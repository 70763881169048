<!-- @format -->
<script>
	import InputErrorsString from './input_errors_string.svelte';
	import SearchBy from '~/svelte/components/ui/dadata/search_by.svelte';

	export let model;
	export let label;
	export let data;
	export let required = false;
	export let disabled = false;
	export let placeholder;
	export let response = {};
	export let errors = [];
	export let type;

	let attributes = Object.keys(data);

	$: fValues = Object.values(data).some(item => item)
		? Object.values(data)
				.filter(value => value)
				.join(' ')
		: null;
	$: iValues = fValues;
	$: outValues = iValues && iValues.length > 0 ? iValues.split(/\s/).filter(item => item.length > 0) : [];
	$: if (outValues) {
		attributes.forEach((item, index) => (response = { ...response, [item]: outValues[index] || null }));
	}

	$: isSuccess = iValues && !(errors ? Boolean(errors.length) : false);

	const parseNamePatronymic = () => {
		let values, namePatronymic;
		values = iValues ? iValues.split(' ') : [];

		if (values.length == 2) {
			if (values[1].match(/^[А-ЯЁа-яё]\.?[А-ЯЁа-яё]\.?$/)) {
				namePatronymic = values[1].split('.');
			} else {
				errors = ['Неверный формат И.О.'];
			}
		}

		iValues = namePatronymic
			? `${values[0].charAt(0).toUpperCase() + values[0].slice(1)} ${namePatronymic[0].toUpperCase()}. ${namePatronymic[1].toUpperCase()}.`
			: values.map(value => value.charAt(0).toUpperCase() + value.slice(1)).join(' ');
	};
</script>

<div class="form-group row" class:has-error={errors ? Boolean(errors.length) : false} class:has-success={isSuccess}>
	<label for={`input-multiple-${model}-${attributes.join('-')}`} class="col-sm-4 col-lg-3 col-xl-4 col-form-label" class:text-disabled={disabled}>
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<SearchBy
			method={type}
			id={`input-multiple-${model}-${attributes.join('-')}`}
			requiredborder={required}
			disabledtext={disabled}
			bind:value={iValues}
			{disabled}
			{placeholder}
			on:blur={parseNamePatronymic}
			autocomplete="off"
		/>
		<InputErrorsString {errors} />
	</div>
</div>

<style>
</style>
