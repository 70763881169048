<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte'
	import { isPresent, isBlank, toArray, formatSum } from 'utils/tools';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import Form from '~/svelte/components/ui/form/_form.svelte';
	import Field from '~/svelte/components/ui/form/_form_field.svelte';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import InputMoney from '~/svelte/components/ui/input_money.svelte';
	import RepaymentOrderRTKInfo from '~/svelte/_shared/repayment_order_rtk_info_block.svelte';
	import IconInfo from '~/svelte/_shared/icon-info.svelte';
	import ScansSet from '~/svelte/_shared/scans_sets/_set_field.svelte'

	const dispatch = createEventDispatcher();

	export let isNewInstance
	export let disabled = false
	export let formOptions;
	export let isCompleted;
	export let cannotBeDeleted = false;
	export let kind;
	export const submitForm = () => formControl.submitForm();
	export const deleteItem = () => formControl.deleteItem();
	export const getItem = () => formControl.getItem();
	export const setItem = changes => formControl.setItem(changes);
	export const getData = () => data;
	export const setData = changes => (data = { ...data, ...changes });

	let formControl;
	let form, errorsBase, handleChange;
	let data = {};

	const setInitialazedForm = instance => {
		form = instance.form;
		errorsBase = instance.errorsBase;
		handleChange = instance.handleChange;
	};

	const setDictionariesValues = values => {
		data = { ...values };
		data.counterparties = [{ value: -1, label: 'Добавить кредитора', action: true }, ...data.counterparties];
		cannotBeDeleted = $form.cannot_be_deleted;
	};
	const setSubmitParams = () => {
		const params = {};

		if (['substitution_partly', 'reduction', 'other'].includes($form.kind)) {
			params.disabled_creditors_requirement_ids = [$form.disabled_creditors_requirement_id];
		}

		params.on_reestr = !!$form.on_reestr;
		params.off_reestr = !!$form.off_reestr;
		params.secured_by_pledge = !!$form.secured_by_pledge;
		params.unsecured_by_pledge = !!$form.unsecured_by_pledge;

		return params;
	};

	const handleNewOldCounterparty = () => dispatch('newOldCounterparty');
	const handleNewNewCounterparty = () => dispatch('newNewCounterparty');

	let old_requirement_sum;
	let old_counterparties = [];
	let new_counterparties = [];
	let creditors_requirements = [];
	let current_counterparty_id;
	let current_creditors_requirement = {};
	let current_requirement_kinds = [];
	let repaymentOrderInfoShow = false;
	let preview = formOptions.method != 'new';

	const kinds = [
		'Основной долг',
		'Обязательные платежи в бюджет',
		'Проценты по договору',
		'Проценты за пользование займом',
		'Судебные расходы',
		'Государственная пошлина',
		'Проценты за просрочку платежа',
		'Пени',
		'Штраф',
		'Проценты за пользование чужими денежными средствами (по ст.395 ГК)',
		'Неустойка',
	];
	const kinds_full = kinds.map(e => ({ value: e, label: e }));
	const kinds_short = kinds
		.slice(0, 1)
		.concat(kinds.slice(2, kinds.length))
		.map(e => ({ value: e, label: e }));

	const showOnReestrField = () => !!current_creditors_requirement.off_reestr;
	const showOffReestrField = () => !showOnReestrField();
	const showUnsecuredByPledgeField = () => {
		if (!current_creditors_requirement.secured_by_pledge) {
			return false;
		} else if (current_creditors_requirement.order_of_repayment == 3) {
			return isBlank($form.order_of_repayment) || $form.order_of_repayment == 3;
		} else if ($form.order_of_repayment == 3) {
			return true;
		} else {
			return false;
		}
	};
	const showSecuredByPledgeField = () => {
		if (current_creditors_requirement.secured_by_pledge) {
			return false;
		} else if (current_creditors_requirement.order_of_repayment == 3) {
			return isBlank($form.order_of_repayment) || $form.order_of_repayment == 3;
		} else if ($form.order_of_repayment == 3) {
			return true;
		} else {
			return false;
		}
	};
	const calcOldRequirementSum = () => {
		if ($form.kind == 'substitution_partly' || $form.kind == 'reduction') {
			if (isPresent($form.disabled_creditors_requirement_id)) {
				const el = data.creditors_requirements.find(e => e.value == $form.disabled_creditors_requirement_id);
				if (isPresent(el)) {
					old_requirement_sum = el.unpaid_sum - $form.requirement_sum;
				} else {
					old_requirement_sum = 0;
				}
			} else {
				old_requirement_sum = 0;
			}
		}
	};
	const refreshRequirementKinds = () => {
		if (current_creditors_requirement.secured_by_pledge) {
			// если выбрано залоговое
			return $form.unsecured_by_pledge ? kinds_full : kinds_short;
		} else {
			// если выбрано НЕзалоговое
			return $form.secured_by_pledge ? kinds_short : kinds_full;
		}
	};

	$: if (isPresent($form)) {
		calcOldRequirementSum($form);

		creditors_requirements = toArray(data.creditors_requirements).filter(e => e.counterparty_id == $form.counterparty_id);
		old_counterparties = toArray(data.counterparties).filter(e => e.value != $form.new_counterparty_id);
		new_counterparties = toArray(data.counterparties).filter(e => e.value != $form.counterparty_id);
		kind = $form.kind;

		current_counterparty_id = current_counterparty_id || $form.counterparty_id;
		current_creditors_requirement = toArray(data.creditors_requirements).find(e => e.value == $form.disabled_creditors_requirement_id) || {};
		current_requirement_kinds = refreshRequirementKinds();

		// если в списке нет выбранного kind, то сбрасывем значение
		if ($form.order_of_repayment == 3 && !current_requirement_kinds.map(e => e.value).includes($form.requirement_kind)) {
			$form.requirement_kind = '';
		}
		//
		// при смене контрагента сбрасываем все чекбоксы и фильтры
		//
		if (current_counterparty_id != $form.counterparty_id) {
			current_counterparty_id = $form.counterparty_id;
			$form.on_reestr = false;
			$form.off_reestr = false;
			$form.secured_by_pledge = false;
			$form.unsecured_by_pledge = false;
			$form.disabled_creditors_requirement_id = '';
			$form.disabled_creditors_requirement_ids = [];
		}
	}

	$: filledRequiredFields = $form &&
		isPresent($form.kind) &&
		isPresent($form.counterparty_id) && Number.isInteger(+$form.counterparty_id) &&
		isPresent($form.disabled_creditors_requirement_ids) &&
		isPresent($form.new_counterparty_id) && Number.isInteger(+$form.new_counterparty_id) &&
		isPresent($form.judicial_act) &&
		isPresent($form.judicial_act_at) &&
		isPresent($form.court_id)

	let creating = false
	$: if (isBlank($form?.id) && filledRequiredFields) {
		if (!creating) { secretlyСreate() }
		creating = true
	}

	const secretlyСreate = async () => {
		const response = await submitForm()
		$form.id = response?.item?.id
		$form.scans_set = { ...response?.item?.scans_set,  }
		creating = false
	}

	$: if (isPresent($form) && !isNewInstance) { isNewInstance = isBlank($form?.id) }
</script>

<Form
	bind:this={formControl}
	bind:isCompleted
	formOptions={{ ...formOptions, model: 'amendement', controller: 'amendements' }}
	{setDictionariesValues}
	{setSubmitParams}
	{setInitialazedForm}
>
	<!-- Вид изменения -->
	<Field required={true} disabled={preview || disabled} name="kind" label="Вид изменения">
		<Select
			name="kind"
			items={toArray(data.kinds)}
			value={$form.kind}
			onchange={handleChange}
			isDisabled={preview || disabled}
			containerClasses="form-control"
			placeholder="Выбрать вид"
		></Select>
	</Field>

	<!-- Старый кредитор / Кредитор -->
	<Field
		required={true}
		disabled={preview || disabled}
		name="counterparty_id"
		label={$form.kind == 'substitution_all' || $form.kind == 'substitution_partly' || $form.kind == 'exclusion' ? 'Старый кредитор' : 'Кредитор'}
	>
		<Select
			name="counterparty_id"
			items={old_counterparties}
			value={$form.counterparty_id}
			onchange={handleChange}
			on:action={handleNewOldCounterparty}
			isDisabled={preview}
			containerClasses="form-control"
			placeholder="Выбрать кредитора"
		></Select>
	</Field>

	<!-- Изменяемые требования -- Замена кредитора на всю сумму / Исключение требования -->
	{#if $form.kind == 'substitution_all' || $form.kind == 'exclusion'}
		<Field required={true} disabled={preview || disabled} name="disabled_creditors_requirement_ids" label="Изменяемые требования">
			<Select
				name="disabled_creditors_requirement_ids"
				items={creditors_requirements}
				value={$form.disabled_creditors_requirement_ids}
				on:select={e => ($form.disabled_creditors_requirement_ids = e.detail)}
				isMulti={true}
				isDisabled={preview || disabled}
				containerClasses="form-control"
				placeholder="Выбрать требования"
			></Select>
		</Field>
	{/if}

	<!-- Изменяемое требование -- Замена кредитора на часть суммы / Уменьшение суммы требования / Прочие изменения-->
	{#if $form.kind == 'substitution_partly' || $form.kind == 'reduction' || $form.kind == 'other'}
		<Field required={true} disabled={preview || disabled} name="disabled_creditors_requirement_id" label="Изменяемое требование">
			<Select
				name="disabled_creditors_requirement_id"
				items={creditors_requirements}
				value={$form.disabled_creditors_requirement_id}
				onchange={handleChange}
				isDisabled={preview || disabled}
				containerClasses="form-control"
				placeholder="Выбрать требование"
			></Select>
		</Field>
	{/if}

	<!-- Новый кредитор -- Замена кредитора на всю сумму / Замена кредитора на часть суммы -->
	{#if $form.kind == 'substitution_all' || $form.kind == 'substitution_partly'}
		<Field required={true} disabled={preview || disabled} name="new_counterparty_id" label="Новый кредитор">
			<Select
				name="new_counterparty_id"
				items={new_counterparties}
				value={$form.new_counterparty_id}
				onchange={handleChange}
				on:action={handleNewNewCounterparty}
				isDisabled={preview || disabled}
				containerClasses="form-control"
				placeholder="Выбрать кредитора"
			></Select>
		</Field>
	{/if}

	<!-- Замена кредитора на часть суммы -->
	{#if $form.kind == 'substitution_partly'}
		<!-- Сумма нового требования нового кредитора (руб.) -->
		<Field required={true} disabled={preview || disabled} name="requirement_sum" label="Сумма нового требования нового кредитора (руб.)" short={true}>
			<InputMoney name="requirement_sum" value={$form.requirement_sum} onchange={handleChange} disabled={preview || disabled} klass="form-control text-right" />
		</Field>
		<!-- Сумма нового требования старого кредитора (руб.) -->
		<Field disabled={true} name="old_requirement_sum" label="Сумма нового требования старого кредитора (руб.)" short={true}>
			<div class="form-control text-right" class:disabled={true}>
				{formatSum(old_requirement_sum)}
			</div>
		</Field>
	{/if}
	<!-- Уменьшение суммы требования -->
	{#if $form.kind == 'reduction'}
		<!-- Исключаемая сумма старого требования (руб.) -->
		<Field disabled={preview || disabled} name="requirement_sum" label="Исключаемая сумма старого требования (руб.)" short={true}>
			<InputMoney name="requirement_sum" value={$form.requirement_sum} onchange={handleChange} disabled={preview || disabled} klass="form-control text-right" />
		</Field>
		<!-- Сумма нового требования (руб.) -->
		<Field disabled={true} name="old_requirement_sum" label="Сумма нового требования (руб.)" short={true}>
			<div class="form-control text-right" class:disabled={true}>
				{formatSum(old_requirement_sum)}
			</div>
		</Field>
	{/if}
	<!-- Прочие изменения -->
	{#if $form.kind == 'other'}
		{#if showOffReestrField($form)}
			<!-- Переносится в За реестр -->
			<Field
				disabled={preview || disabled}
				name="off_reestr"
				label="Переносится в За реестр"
				fieldClasses="col-sm-8 col-lg-9 col-xl-8 d-flex align-items-center checkbox-left"
			>
				<input
					id="checkbox_off_reestr"
					name="off_reestr"
					type="checkbox"
					class="align-self-center m-r-sm m-l-xs"
					disabled={preview || disabled}
					bind:checked={$form.off_reestr}
					on:change={handleChange}
				/>
				<label class="label-text" for="checkbox_off_reestr" style="margin-bottom: 0;">да</label>
			</Field>
		{:else if showOnReestrField($form)}
			<!-- Переносится в Реестровые -->
			<Field
				disabled={preview || disabled}
				name="on_reestr"
				label="Переносится в Реестровые"
				fieldClasses="col-sm-8 col-lg-9 col-xl-8 d-flex align-items-center checkbox-left"
			>
				<input
					id="checkbox_on_reestr"
					name="on_reestr"
					type="checkbox"
					class="align-self-center m-r-sm m-l-xs"
					disabled={preview || disabled}
					bind:checked={$form.on_reestr}
					on:change={handleChange}
				/>
				<label class="label-text" for="checkbox_on_reestr" style="margin-bottom: 0;">да</label>
			</Field>
		{/if}

		<!-- Новая очередность погашения -->
		<Field disabled={preview || disabled} name="order_of_repayment" label="Новая очередность погашения">
			<Select
				name="order_of_repayment"
				items={data.order_of_repayments}
				value={$form.order_of_repayment}
				onchange={handleChange}
				isDisabled={preview || disabled}
				containerClasses="form-control"
				placeholder="Выбрать очередь"
			></Select>

			<div
				class="wrapper-info-icon"
				role="button"
				tabindex="0"
				on:mouseover={() => (repaymentOrderInfoShow = true)}
				on:mouseout={() => (repaymentOrderInfoShow = false)}
				on:focus={() => (repaymentOrderInfoShow = true)}
				on:blur={() => (repaymentOrderInfoShow = false)}
			>
				<IconInfo />
			</div>

			{#if repaymentOrderInfoShow}
				<div class="repayment_order__popover show fade">
					<RepaymentOrderRTKInfo />
				</div>
			{/if}
		</Field>

		{#if showSecuredByPledgeField($form)}
			<!-- Становится залоговым -->
			<Field
				disabled={preview || disabled}
				name="secured_by_pledge"
				label="Становится залоговым"
				fieldClasses="col-sm-8 col-lg-9 col-xl-8 d-flex align-items-center checkbox-left"
			>
				<input
					id="checkbox_secured_by_pledge"
					name="secured_by_pledge"
					type="checkbox"
					class="align-self-center m-r-sm m-l-xs"
					disabled={preview || disabled}
					bind:checked={$form.secured_by_pledge}
					on:change={handleChange}
				/>
				<label class="label-text" for="checkbox_secured_by_pledge" style="margin-bottom: 0;">да</label>
			</Field>
		{:else if showUnsecuredByPledgeField($form)}
			<!-- Становится не залоговым -->
			<Field
				disabled={preview || disabled}
				name="unsecured_by_pledge"
				label="Становится не залоговым"
				fieldClasses="col-sm-8 col-lg-9 col-xl-8 d-flex align-items-center checkbox-left"
			>
				<input
					id="checkbox_unsecured_by_pledge"
					name="unsecured_by_pledge"
					type="checkbox"
					class="align-self-center m-r-sm m-l-xs"
					disabled={preview || disabled}
					bind:checked={$form.unsecured_by_pledge}
					on:change={handleChange}
				/>
				<label class="label-text" for="checkbox_unsecured_by_pledge" style="margin-bottom: 0;">да</label>
			</Field>
		{/if}

		<!-- Новый вид обязательства -->
		<Field disabled={preview || disabled} name="requirement_kind" label="Новый вид обязательства">
			{#if $form.order_of_repayment == 3 || (current_creditors_requirement.order_of_repayment == 3 && $form.order_of_repayment != 1 && $form.order_of_repayment != 2)}
				<Select
					name="requirement_kind"
					items={current_requirement_kinds}
					value={$form.requirement_kind}
					onchange={handleChange}
					isDisabled={preview || disabled}
					containerClasses="form-control"
					placeholder="Выбрать вид"
				/>
			{:else}
				<input
					name="requirement_kind"
					type="text"
					class="form-control"
					bind:value={$form.requirement_kind}
					on:change={handleChange}
					disabled={preview || disabled}
					placeholder="Введите вид"
				/>
			{/if}
		</Field>
	{/if}

	<!-- Судебный акт о внесении изменений -->
	<Field required={true} disabled={preview || disabled} name="judicial_act" label="Судебный акт о внесении изменений">
		<Select
			name="judicial_act"
			items={data.judicial_acts}
			value={$form.judicial_act}
			onchange={handleChange}
			isDisabled={preview || disabled}
			containerClasses="form-control"
			placeholder="Выбрать акт"
		></Select>
	</Field>
	<!-- Дата судебного акта -->
	<Datepicker
		required={true}
		model='amendement'
		attribute='judicial_act_at'
		label='Дата судебного акта'
		placeholder='дд.мм.гггг'
		maskOptions={{ mask: Date }}
		disabled={preview || disabled}
		bind:value={$form.judicial_act_at}
	/>
	<!-- Наименование суда -->
	<Field required={true} disabled={preview || disabled} name="court_id" label="Наименование суда">
		<Select
			name="court_id"
			items={data.courts}
			value={$form.court_id}
			onchange={handleChange}
			isDisabled={preview || disabled}
			containerClasses="form-control"
			placeholder="Выбрать суд"
		></Select>
	</Field>
	<ScansSet label='Документы', scansSet={$form.scans_set} disabled={!filledRequiredFields} on:changed={({detail: scansSet}) => $form.scans_set = scansSet}/>
	<ErrorMessage message={ $errorsBase } on:click={() => $errorsBase = null}/>
</Form>

<style>
	.wrapper-info-icon {
		position: absolute;
		top: 0.5rem;
		right: -0.5rem;
		z-index: 10003;
	}
	.repayment_order__popover {
		position: absolute;
		z-index: 10010;
		background-color: #fff;
		border: solid 1px;
		border-radius: 0.3rem;
		box-shadow: 0 0 10px;
		display: block;
		padding: 0.7rem 1rem;
		top: 50px;
	}
	.disabled {
		color: #a8a8a8;
		background-color: #f9f7f7 !important;
	}
</style>
