<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { autosaveStatus, transmitter as transmitter0 } from 'base_stores';
	import { fetchPost, fetchPut } from 'utils/fetch';
	import { validate } from 'tools/validators';
	import InputErrorsString from './input_errors_string.svelte';
	import Hint from './hint.svelte';
	import imask from 'imask';
	import { toString } from 'utils/tools';
	import moneyOptions from '~/svelte/_shared/money_options';

	export let attribute;
	export let model;
	export let url;
	export let id;
	export let childModel = model;
	export let childId = id;
	export let label;
	export let transmitter;
	export let skipVerificationRequired = true;
	export let value;
	export let placeholder = '';
	export let required = false;
	export let disabled = false;
	export let additionalParams = {};
	export let hintData;
	export let saveAutomatically = true;
	export let inputCssClasses = '';

	const dispatch = createEventDispatcher();
	const inputId = `input-currency-${model}-${attribute}`;

	let inputField;
	let dataErrors;
	let previousValue = null;
	let currentValue;

	let imaskInstance;

	const imaskTemplate = node => {
		imaskInstance = imask(node, moneyOptions);
		imaskInstance.on('complete', () => (currentValue = value = imaskInstance.unmaskedValue));

		return {
			destroy() {
				imaskInstance.destroy();
			},
		};
	};

	$: if (imaskInstance && !currentValue && value != currentValue) imaskInstance.unmaskedValue = toString(value);

	$: method = id ? fetchPut : fetchPost;
	$: if (id) {
		url = `${url}/${id}`;
	}
	$: isSuccess = value && !dataErrors;
	$: if (!previousValue) {
		previousValue = value;
	}

	const handleChange = () => {
		$autosaveStatus = null;

		if (previousValue !== value) {
			dataErrors = validate(model, attribute, parseFloat(value), skipVerificationRequired);

			if (!dataErrors) {
				//let params = { [model]: { [attribute]: parseFloat(value) }, [`${childModel}_id`]: childId, ...additionalParams };

				let valueHash = attribute
					.split('.')
					.reverse()
					.reduce((acc, curr) => ({ [curr]: acc }), value);
				let params = { [model]: valueHash, [`${childModel}_id`]: childId, ...additionalParams };

				method(url, params)
					.then(response => {
						if (JSON.stringify(response) !== '{}') {
							$autosaveStatus = 'saved';
						}
						transmitter = { ...transmitter, ...response };
						$transmitter0 = { ...$transmitter0, ...response };
						previousValue = value;
						if (transmitter.not_saved) {
							$autosaveStatus = 'not_saved';
						}
						if ($transmitter0.not_saved) {
							$autosaveStatus = 'not_saved';
						}
						dispatch('update', response);
					})
					.catch(_errors => {
						$autosaveStatus = 'not_saved';
					});
				return;
			}
			$autosaveStatus = 'not_saved';
		}
	};
</script>

<div class="form-group row" class:has-error={dataErrors} class:has-success={isSuccess}>
	<label for={inputId} class="col-sm-4 col-lg-3 col-xl-4 col-form-label">
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8 float-right">
		<input
			id={inputId}
			type="text"
			class:required-border={required}
			class:text-disabled={disabled}
			class={`form-control text-right ${inputCssClasses}`}
			{placeholder}
			bind:this={inputField}
			use:imaskTemplate
			{disabled}
			on:focus={() => inputField.select()}
			on:change={saveAutomatically && handleChange}
			autocomplete="off"
		/>
	</div>
	{#if dataErrors}
		<InputErrorsString errors={dataErrors} />
	{:else if hintData}
		<Hint {...hintData} />
	{/if}
</div>

<style>
	input::placeholder {
		padding-right: 1px;
	}
</style>
