<!-- @format -->
<script>
	import { transmitter, options } from 'base_stores'
	import { fetchGet, fetchPost, fetchPut, fetchDelete } from 'utils/fetch'
	import { onMount, createEventDispatcher } from 'svelte'
	import { showModal, hideModal } from '../components/modal.svelte'
  import { isPresent, isBlank } from 'utils/tools'
	import { clearScans } from '~/svelte/_shared/scans_sets/scans_set.js'
	import Input from '../components/ui/input.svelte'
	import Select2 from '../components/ui/select2.svelte'
	import Datepicker from '../components/ui/datepicker.svelte'
	import Textarea from '../components/ui/textarea.svelte'
  import CounterpartyModal from '../counterparties/_modal.svelte'
	import ScansSet from '~/svelte/_shared/scans_sets/_set_field.svelte'

	const dispatch = createEventDispatcher()

	export let complaintId;
	export let noComplainerSelected;
	export let handleSubmit;
	export let handleCancel;
	export let handleDelete;
	export let disabled = false;

	const nullComplaint = {
		date: null,
		counterparty_id: null,
		complainer_type: null,
		content: null,
		article: null,
		decisionmaker: null,
		consideration_date: null,
		final_document: null,
		decision: null,
		decision_revision: null,
		scans_set: {},
	};

	const complainerTypeOptions = [
		{ id: 'rosreestr', text: 'Росреестр' },
		{ id: 'counterparty', text: 'Кредитор' },
		{ id: 'other', text: 'Другое' },
	];

	let isNewInstance
	let complaint = { ...nullComplaint }
	let complainer = { rosreestr: null, other: null }
	let tempCounterparty = null
	let counterpartyId

	$: method = isPresent(complaintId) ? fetchPut : fetchPost
	$: complainerType = complaint?.complainer_type
	$: data = { ...complaint, complainer: complainer[complainerType] }
	$: noComplainerSelected = !complainer[complainerType] && !complaint.counterparty_id

	$: if (!complaintId) {
		complaint = { ...nullComplaint };
	}

	$: if (complaintId && complaintId !== complaint.id) {
		fetchGet(`/api/private/complaints/${complaintId}`).then(response => {
			complaint = response.complaint;
			complainer[complaint.complainer_type] = complaint.complainer;
		});
	}

	$: if (complaint.complainer_type !== 'counterparty') { complaint.counterparty_id = null }
	
	//$: if ($transmitter && $transmitter.counterparties) {
	//	const counterpartiesLen = $transmitter.counterparties.length
	//	const newCounterparty = counterpartiesLen && $transmitter.counterparties[0]

	$: formFields = {
		complainerType: {
			required: true,
			model: 'complaint',
			withSearch: false,
			attribute: 'complainer_type',
			label: 'Вид заявителя',
			placeholder: 'Выбрать вид',
			options: complainerTypeOptions,
			disabled,
		},
		complainerName: {
			required: true,
			model: 'complaint',
			label: 'Заявитель',
			placeholder: complainerType == 'other' ? 'ООО “Компания” или Фамилия И.О.' : 'Выбрать заявителя',
			options: complainerType === 'rosreestr' ? $options.rosreestr : $options.counterparty,
			modify: complainerType === 'counterparty' ? 'showModalCounterpartyForm' : null,
			addOption: complainerType === 'rosreestr' && addCorrespondentOption,
			disabled,
		},
		date: {
			model: 'complaint',
			attribute: 'date',
			label: 'Дата поступления жалобы',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
			disabled,
		},
		content: {
			model: 'complaint',
			attribute: 'content',
			label: 'Содержание жалобы',
			placeholder: 'Содержание жалобы',
			disabled,
		},
		article: {
			model: 'complaint',
			attribute: 'article',
			label: 'Статья',
			placeholder: 'ч.0 ст.00.00 КоАП РФ',
			disabled,
		},
		decisionmaker: {
			model: 'complaint',
			attribute: 'decisionmaker',
			label: 'Кто принимает решение',
			placeholder: 'Выбрать',
			options: complainerType === 'rosreestr' ? $options.court : $options.rosreestr,
			addOption: complainerType !== 'rosreestr' ? addCorrespondentOption : null,
			disabled,
		},
		considerationDate: {
			model: 'complaint',
			attribute: 'consideration_date',
			label: 'Дата рассмотрения',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
			disabled,
		},
		finalDocument: {
			model: 'complaint',
			attribute: 'final_document',
			label: 'Документ по итогам рассмотрения',
			placeholder: 'Решение по делу №А00-00000/0000',
			disabled,
		},
		decision: {
			model: 'complaint',
			attribute: 'decision',
			label: 'Решение',
			placeholder: 'Административный штраф 0 руб.',
			disabled,
		},
		decisionRevision: {
			model: 'complaint',
			attribute: 'decision_revision',
			label: 'Пересмотр решения',
			placeholder: 'Апелляционная жалоба на рассмотрении 1 суда',
			disabled,
		},
	};

	$: if ($transmitter && $transmitter.counterpartyFormCanceled) {
		complaint.counterparty_id = tempCounterparty;
		$transmitter = { ...$transmitter, counterpartyFormCanceled: false };
		tempCounterparty = null;
	}

	$: filledRequiredFields = isPresent(complaint.complainer_type) && (
		complainerType == 'counterparty' && isPresent(complaint.counterparty_id) && Number.isInteger(+complaint.counterparty_id) ||
		['rosreestr', 'other'].includes(complainerType) && isPresent(complainer[complainerType])
	)

	$: if (isBlank(complaintId) && filledRequiredFields) { secretlyСreate() }

	const secretlyСreate = async () => {
		const response = await fetchPost('/api/private/complaints', data)
		$transmitter.complaints = response?.complaints
		complaint = $transmitter.complaints.find(c => c.id == response?.complaint_id)
		complaintId = response?.complaint_id
	}

	const refreshScansSet = ({detail: scansSet}) => {
		$transmitter.complaints = $transmitter.complaints.map(s => s.id == complaintId ? { ...s, scans_set: scansSet } : s)
		complaint.scans_set = scansSet
	}

	const showModalCounterpartyForm = (value) => {
		if (value === 'new') {
			tempCounterparty = complaint.counterparty_id;
			openCounterpartyModalForm(tempCounterparty);
		}
	};

	const openCounterpartyModalForm = id => {
		counterpartyId = id;
		showModal('form-counterparty-modal');
	};

	const addCorrespondentOption = async text => {
		const params = { text, correspondent_kind: 'rosreestr' };
		const response = await fetchPost('/api/private/addressees/create_and_return_option', params);
		const option = response.option;

		fetchGet('/api/private/organizations/options', { correspondent_kind: 'rosreestr' }).then(response => {
			$options.rosreestr = [
				{ id: '', text: '' },
				...response.options.map(rosreestr => {
					return { id: rosreestr.text, text: rosreestr.text, user_id: rosreestr.user_id };
				}),
			];

			if (complainerType === 'rosreestr') {
				complainer['rosreestr'] = option.text;
			} else {
				complaint.decisionmaker = option.text;
			}
		});
	};

	handleSubmit = async () => {
		if (complaint.complainer_type) {
			//return method(`/api/private/complaints${complaintId ? `/${complaintId}` : ''}`, data).then(response => {
			//	$transmitter = { ...$transmitter, complaints: response.complaints };
			//	hideModal('form-complaint-modal');
			//});
			//.catch(errors => console.log('errors: ' + JSON.stringify(errors)))
			const response = await method(`/api/private/complaints${complaintId ? `/${complaintId}` : ''}`, data)
			$transmitter = { ...$transmitter, complaints: response.complaints }

			const scansSet = await clearScans(complaint.scans_set, 'deleted')
			$transmitter.complaints = $transmitter.complaints?.map(s => s.id == complaintId ? { ...s, scans_set: scansSet } : s)

			hideModal('form-complaint-modal')
			dispatch('submit')
		}
	};

	handleDelete = () => {
		return fetchDelete(`/api/private/complaints/${complaintId}`)
			.then(response => {
				$transmitter = { ...$transmitter, complaints: response.complaints }
				dispatch('delete')
			})
	}

	handleCancel = () => {
		if (isNewInstance) {
			if (isPresent(complaintId)) { handleDelete() }
		} else {
			clearScans(complaint.scans_set, 'new')
		}

		complaint = { ...nullComplaint }
	}

	const getOptions = async () => {
		const response = await fetchGet('/api/private/counterparties/get_options_for_roles', { roles: ['creditor'] });
		$options.counterparty = [{ id: null, text: '' }, { id: 'new', text: 'Добавить кредитора' }, ...response.options];
	};

	const refresh = async newCounterpartyId => {
		await getOptions();
		complaint.counterparty_id = newCounterpartyId;
	};

	onMount(async () => {
		isNewInstance = isBlank(complaintId)
		$options = { ...$options, rosreestr: [], counterparty: [], court: [] }

		fetchGet('/api/private/organizations/options', { correspondent_kind: 'rosreestr' })
			.then(
				response =>
					($options.rosreestr = [
						{ id: '', text: '' },
						...response.options.map(rosreestr => {
							return { id: rosreestr.text, text: rosreestr.text, user_id: rosreestr.user_id };
						}),
					]),
			)
			.then(() => fetchGet('/api/private/courts/get_options'))
			.then(
				response =>
					($options.court = [
						...response.options.map(court => {
							return { id: court.text, text: court.text };
						}),
					]),
			)
			.then(() => fetchGet('/api/private/counterparties/get_options_for_roles', { roles: 'creditor' }))
			.then(response => ($options.counterparty = [{ id: '', text: '' }, { id: 'new', text: 'Добавить кредитора' }, ...response.options]));
	});
</script>

<Select2 {...formFields.complainerType} bind:value={complaint.complainer_type} />
{#if complainerType}
	{#if complainerType == 'counterparty'}
		<Select2 {...formFields.complainerName} bind:value={complaint.counterparty_id} attribute={'counterparty_id'} modify={showModalCounterpartyForm}/>
	{:else if complainerType == 'rosreestr'}
		<Select2 {...formFields.complainerName} bind:value={complainer.rosreestr} attribute={'complainer'}/>
	{:else if complainerType == 'other'}
		<Input {...formFields.complainerName} bind:value={complainer.other} attribute={'complainer'}/>
	{/if}
	<Datepicker {...formFields.date} bind:value={complaint.date} />
	<Textarea {...formFields.content} bind:value={complaint.content}/>
	<Input {...formFields.article} bind:value={complaint.article}/>
	<Select2 {...formFields.decisionmaker} bind:value={complaint.decisionmaker}/>
	<Datepicker {...formFields.considerationDate} bind:value={complaint.consideration_date}/>
	<Input {...formFields.finalDocument} bind:value={complaint.final_document}/>
	<Input {...formFields.decision} bind:value={complaint.decision}/>
	<Input {...formFields.decisionRevision} bind:value={complaint.decision_revision}/>
	<ScansSet label='Документы', scansSet={complaint.scans_set} disabled={!filledRequiredFields} on:changed={refreshScansSet}/>
{/if}

<CounterpartyModal {counterpartyId} fixedRoles={['creditor']} on:create={({ detail: newCounterpartyId }) => refresh(newCounterpartyId)} index="1" />
