<!-- @format -->
<script>
	import TrialPeriodSign from './trial_period_sign.svelte';
	import AutosaveBadge from './autosave_badge.svelte';
	import Payment from './payment/base.svelte';
	import ToolsBar from './tools_bar.svelte';
	import { procedure } from 'base_stores';

	export let headerHeight;

	let hamburgerMenuActive = false;

	$: showInfo = !!$procedure || location.pathname.match(/^\/arbitr_managers.*/);
	$: topMargin = `${headerHeight + 5}px`;
</script>

<nav class="navbar navbar-fixed-top" bind:offsetHeight={headerHeight}>
	<div class="flex flex-wrap flex-sm-nowrap d-block d-sm-flex">
		<div class="navbar-brand page_title">
			<div class="name-wrapper m-r-xs">
				<slot name="name" />
			</div>
			{#if showInfo && !location.pathname.match(/^\/admin.*/)}
				<TrialPeriodSign />
				<Payment />
				<AutosaveBadge />
			{/if}
		</div>
		{#if !location.pathname.match(/^\/admin.*/)}
			<ToolsBar />
		{/if}
		<div
			class="hamburger-menu"
			class:hamburger-menu-active={hamburgerMenuActive}
			role="button"
			tabindex="0"
			on:click={() => (hamburgerMenuActive = !hamburgerMenuActive)}
			on:keypress|stopPropagation
		>
			<span class="hamburger-menu-line"></span>
			<span class="hamburger-menu-line"></span>
			<span class="hamburger-menu-line"></span>
		</div>
	</div>
	{#if $$slots.tabs}
		<div class="tabs-wrapper mt-2 mt-sm-0">
			<slot name="tabs" />
		</div>
	{/if}
	<div class="dashboard-wrapper">
		<slot name="dashboard" />
	</div>
</nav>
<div style={`margin-top: ${topMargin};`}></div>

<style>
	.dashboard-wrapper,
	.tabs-wrapper {
		width: 100%;
	}
	.tabs-wrapper {
		width: 100%;
		overflow: auto;
	}
	.name-wrapper {
		display: block;
		float: left;
	}

	.page_title {
		width: 100%;
	}
	@media (max-width: 576px) {
		.page_title {
			width: 85%;
			overflow: auto;
		}
		.page_title::-webkit-scrollbar {
			display: none;
		}
	}
</style>
