import { fetchPut } from 'utils/fetch'
import { isBlank } from 'utils/tools'

export const clearScans = async (scansSet, status) => {
  const scanIds = scansSet.scans.map(scan => scan[status] ? scan.id : null).filter(s => s)
  scansSet.scans = scansSet.scans.filter(scan => !scan[status]).map(scan => { delete(scan.new); delete(scan.deleted); return scan; })

  if (isBlank(scanIds)) { return scansSet }

  await fetchPut(`/api/private/scans_sets/${scansSet.id}/remove_scans`, { scan_ids: scanIds })

  if (isBlank(scansSet.scans)) { scansSet.id = null }

  return scansSet
}
