<!-- @format -->
<script>
  import { onMount } from 'svelte'
  import { transmitter } from 'base_stores'
  import { fetchGet, fetchPut, fetchPost, fetchDelete } from 'utils/fetch'
  import { hideModal } from '~/svelte/components/modal.svelte'
  import { isPresent, isBlank } from 'utils/tools'
	import { clearScans } from '~/svelte/_shared/scans_sets/scans_set.js'
  import Textarea from '~/svelte/components/ui/textarea.svelte'
  import Datepicker from '~/svelte/components/ui/datepicker.svelte'
  import InputInteger from '~/svelte/components/ui/input_integer.svelte'
  import Checkbox from '~/svelte/components/ui/checkbox.svelte'
  import Select2 from '~/svelte/components/ui/select2.svelte'
	import ScansSet from '~/svelte/_shared/scans_sets/_set_field.svelte'

	export let event;
	export let selectedProcedureIds = [];
	export let exceptCompleted = true;
	export let requiredFieldsFilled = false;
	export let searchParams;
	export let handleSubmit;
	export let handleCancel;
	export let handleDelete;

	let isNewInstance
  let procedureOptions = null

	let nullData = {
		event: {
			name: null,
			delay_before_reminder: null,
			notice: null,
			expiration_date: null,
			expiration_time: null,
			completed: null,
			procedure_id: null,
		},
	};

	$: data = { event: { ...nullData.event } };
	$: requiredFieldsFilled = data.event.name && data.event.expiration_date && data.event.procedure_id;
	$: method = event?.id ? fetchPut : fetchPost;

  let eventData = {
    event: {
      name: event && event.name,
      delay_before_reminder: event && event.delay_before_reminder,
      notice: event && event.notice,
      expiration_date: event && event.expiration_date,
      expiration_time: event && event.expiration_time,
      completed: event && event.completed,
      procedure_id: event && event.procedure_id,
      scans_set: event && event.scans_set,
    }
  }

	$: if (event) {
		data = { ...eventData };
	}

	$: fieldsData = {
		name: {
			required: true,
			label: 'Что сделать',
			placeholder: 'Судебное заседание о включении требования ООО "Кредитор"',
		},
		expirationDate: {
			required: true,
			label: 'Срок',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
		},
		delayBeforeReminder: {
			label: 'Напоминание (за сколько дней до срока)',
			placeholder: '1',
		},
		completed: {
			required: true,
			label: 'Выполнено',
			text: 'Да',
		},
		status: {
			label: 'Состояние задачи',
		},
		notice: {
			label: 'Пояснение',
			placeholder: 'Представить отзыв до судебного заседания',
		},
		procedureId: {
			required: true,
			label: 'Процедура',
			placeholder: 'Выберите процедуру',
			options: procedureOptions,
		},
	};

  $: filledRequiredFields = isPresent(data.event.name) && isPresent(data.event.expiration_date) && isPresent(data.event.procedure_id)

  $: if (isBlank(event?.id) && filledRequiredFields) { secretlyСreate() }

  const secretlyСreate = async () => {
    const response = await fetchPost('/api/private/events',
      { ...data, except_completed: exceptCompleted, procedure_ids: selectedProcedureIds.length ? JSON.stringify(selectedProcedureIds) : null })
    $transmitter.events = response.events
    eventData.event = response?.event
    data = { ...eventData }
  }

  const refreshScansSet = ({detail: scansSet}) => {
    $transmitter.events = $transmitter.events.map(s => s.id == event.id ? { ...s, scans_set: scansSet } : s)
    event.scans_set = scansSet
  }

  handleSubmit = async () => {
    const response = await method(
      `/api/private/events${event?.id ? `/${event.id}` : ''}`,
      { ...data, except_completed: exceptCompleted, procedure_ids: selectedProcedureIds.length ? JSON.stringify(selectedProcedureIds) : null }
    )

    $transmitter = { ...$transmitter, events: response.events }
    data = { ...nullData }

    const scansSet = await clearScans(event.scans_set, 'deleted')
    $transmitter.events = $transmitter.events.map(s => s.id == event.id ? { ...s, scans_set: scansSet } : s)

    hideModal('event-modal')
  }

  handleCancel = () => {
		if (isNewInstance) {
			if (isPresent(event?.id)) { handleDelete() }
		} else {
			clearScans(event.scans_set, 'new')
		}

    const url = new URL(location)

		url.searchParams.delete('id');
		history.replaceState(null, null, url);
		searchParams = null;

		data = { ...nullData };
		hideModal('event-modal');
	};

	handleDelete = () => {
		return fetchDelete(`/api/private/events/${event.id}`, {
			except_completed: exceptCompleted,
			procedure_ids: selectedProcedureIds.length ? JSON.stringify(selectedProcedureIds) : null,
		}).then(response => {
			$transmitter = { ...$transmitter, events: response.events };
		});
	};

	onMount(() => {
		isNewInstance = isBlank(event?.id)
		fetchGet('/api/private/procedures/procedure_options').then(response => (procedureOptions = response.options));
	});
</script>

{#if !event || event.personal}
	<Textarea {...fieldsData.name} bind:value={data.event.name} />
{:else}
	<div class="form-group row">
		<div class="col-sm-4">Что сделать</div>
		<div class="col-sm-8">{event && event.name}</div>
	</div>
{/if}
<Datepicker {...fieldsData.expirationDate} bind:value={data.event.expiration_date} />
<div class="form-group row" class:has-success={data.event.expiration_time}>
	<label for="expiration_time" class="col-sm-4 col-lg-3 col-xl-4 col-form-label"> Время </label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<input id="expiration_time" class="expiration_time form-control" type="time" autocomplete="off" bind:value={data.event.expiration_time} />
	</div>
</div>
{#if !event || event.personal}
	<InputInteger {...fieldsData.delayBeforeReminder} bind:value={data.event.delay_before_reminder} />
{:else}
	<div class="form-group row">
		<div class="col-sm-4">Напоминание (за сколько дней до срока)</div>
		<div class="col-sm-8">{(event && event.delay_before_reminder) || 'Нет'}</div>
	</div>
{/if}
<Checkbox {...fieldsData.completed} bind:checked={data.event.completed} />
<div class="form-group row">
	<div class="col-sm-4">{fieldsData.status.label}</div>
	<div class="col-sm-8">{data.event.completed ? 'Выполнено' : 'Не выполнено'}</div>
</div>
{#if !event || event.personal}
	<Textarea {...fieldsData.notice} bind:value={data.event.notice} />
{:else}
	<div class="form-group row">
		<div class="col-sm-4">Пояснение</div>
		<div class="col-sm-8">{(event && event.notice) || 'Нет'}</div>
	</div>
{/if}
{#if !event || event.personal}
	<Select2 {...fieldsData.procedureId} bind:value={data.event.procedure_id} />
{:else}
	<div class="form-group row">
		<div class="col-sm-4">Процедура</div>
		<div class="col-sm-8">{event && event.procedure_name}</div>
	</div>
{/if}
<ScansSet label='Документы', scansSet={data.event.scans_set} disabled={!filledRequiredFields} on:changed={refreshScansSet}/>

<style>
	.expiration_time {
		padding-left: 0.7rem;
		padding-right: 0.6rem;
		height: 100%;
		width: auto;
	}
</style>
