<!-- @format -->
<script>
	export let selectedTags = [];
	export let tags;

	function toggleTag(tag) {
		if (selectedTags.includes(tag)) {
			selectedTags = selectedTags.filter(selectedTag => selectedTag !== tag);
		} else {
			selectedTags = [...selectedTags, tag];
		}
	}
</script>

<div class="tag-list">
	{#each tags as tag}
		<div class="tag" class:selected-tag={selectedTags.includes(tag)} role="button" tabindex="0" on:click={() => toggleTag(tag)} on:keypress|stopPropagation>
			{tag}
		</div>
	{/each}
	<div
		class="tag"
		class:selected-tag={selectedTags.includes('withoutTags')}
		role="button"
		tabindex="0"
		on:click={() => toggleTag('withoutTags')}
		on:keypress|stopPropagation
	>
		без тегов
	</div>
	<div class="tag" style="padding: 2px 5px 1px;" role="button" tabindex="0" on:click={() => (selectedTags = [])} on:keypress|stopPropagation>✕</div>
</div>

<style lang="scss">
	.tag-list {
		display: flex;
		flex-wrap: wrap;
	}

	.selected-tag {
		background-color: #6c757d !important;
		color: #fff !important;
	}

	.tag {
		background-color: #e1e1e1;
		font-size: 13px;
		color: #100f0f;
		border-radius: 3px;
		margin: 4px;
		padding: 2px 8px 2px;
		/*-webkit-transition: all 0.2s 0.01s ease-in;*/
		cursor: pointer;
	}

	.tag:hover {
		background-color: #b4b2b2 !important;
	}
</style>
