<!-- @format -->
<script>
	import { fieldsReady, procedure, transmitter } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { dctFormAvailable } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from 'utils/fetch';
	import { stripHtml } from 'utils/formats';
	import { showModal } from '~/svelte/components/modal.svelte';
	import CounterpartyModal from '~/svelte/counterparties/_modal.svelte';
	import { onMount } from 'svelte';
	import { formatSum } from 'utils/tools';

	export let fieldId;

	let previousCounterparties;
	let procedureId = null;
	let kdlId = null;
	let indexCollapse = false;
	let forceSave = true;

	$: $fieldsReady[fieldId] = !forceSave;
	$: procedureId = $procedure && $procedure.id;
	$: kdls = ($transmitter && $transmitter.kdls) || kdls || [];
	$: if (previousCounterparties?.length !== $transmitter.kdls?.length) {
		rewriteKdls($transmitter.kdls);
		previousCounterparties = $transmitter.kdls;
	}

	const openModalForm = id => {
		kdlId = id;
		showModal('form-counterparty-modal');
	};

	const rewriteKdls = () => {
		$transmitter.kdls = $transmitter.counterparties
			.filter(c => c.roles.includes('kdl'))
			.map(kdl => {
				return {
					id: kdl.id,
					full_name: kdl.full_name,
					submission_claims_date: kdl.additions.kdl?.submission_claims_date,
					amount_of_claims: kdl.additions.kdl?.amount_of_claims,
					sum: formatSum(kdl.additions.kdl?.amount_of_claims),
					result: kdl.additions.kdl?.result && stripHtml(kdl.additions.kdl.result),
				};
			});
	};

	const setCounterparty = async newCounterpartyId => {
		try {
			const response = await fetchGet('/api/private/counterparties/' + newCounterpartyId);
			$transmitter.counterparties.push(response.counterparty);
			rewriteKdls();
		} catch (error) {
			console.error(error);
		}
	};

	onMount(async () => {
		try {
			const response = await fetchGet('/api/private/counterparties');
			$transmitter = { ...$transmitter, kdls: response.counterparties };
		} catch (error) {
			console.error(error);
		}
	});
</script>

<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
	<h4 class="kdls col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{`Привлеченные к субсидиарной ответственности (${kdls.length})`}</span>
	</h4>
</div>
<div id="collapse-kdls" class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<button
			class="btn btn-xs btn-warning m-b-xs"
			disabled={(!$procedureAvailable || !procedureId) && !$dctFormAvailable}
			on:click={procedureId && $procedureAvailable && $dctFormAvailable && openModalForm(null)}
		>
			+ КДЛ
		</button>
		<div class="p-1">
			{#if kdls.length}
				<ol class="kdls-list">
					{#each kdls as kdl}
						<li>
							<div class="float-left simulated-link" role="button" tabindex="0" on:click={openModalForm(kdl.id)} on:keypress|stopPropagation>
								{kdl.full_name}
							</div>
						</li>
					{/each}
				</ol>
			{/if}
		</div>
	</div>
</div>
<CounterpartyModal counterpartyId={kdlId} fixedRoles={['kdl']} on:create={({ detail: newCounterpartyId }) => setCounterparty(newCounterpartyId)} />

<style>
	.kdls-list {
		display: grid;
		padding-left: 15px;
		list-style-type: circle;
	}
	.kdls-list > li > div,
	.kdls > .header {
		cursor: pointer;
	}
</style>
