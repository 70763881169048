<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { transmitter } from 'base_stores';
	import { errorMessage } from './store';
	import { fetchGet, fetchPut } from 'utils/fetch';
	import { showModal } from '~/svelte/components/modal.svelte';
	import { isPresent, isBlank } from 'utils/tools';
	import SvelteTooltip from 'svelte-tooltip';
	import EventModal from './_modal.svelte';
	import GoogleSyncButton from './google_sync_button.svelte';
	import Message from '~/svelte/components/message.svelte';
	import moment from 'moment';

	const tableTitles = ['Выполнено', 'Срок', 'Что сделать', 'Процедура'];
	const keyCalenderSelected = 'calender.selected';
	// Для адаптирование под мобилки заменяет текст Выполнено на ✓
	if (innerWidth < 576) tableTitles.splice(0, 1, '✓');

	let pages = [];
	let eventData = null;
	let arbitrManagers = [];
	let exceptCompleted = true;
	let requiredFieldsFilled = false;
	let undoneEvents = [];
	let searchParams = new URL(location).searchParams;
	let mounted = false;
	let selectedProcedureIds = [];
	let showFilter = false;

	$: events = $transmitter && $transmitter.events;
	$: pagination = $transmitter && $transmitter.pagination;

	$: if (pagination && pagination.total_pages !== pages.length) {
		pages = [...Array(pagination.total_pages).keys()].map(i => ++i);
	}

	$: if (mounted && (selectedProcedureIds || exceptCompleted)) {
		getFilteredEvents();
	}

	$: if (searchParams && searchParams.get('id') && isPresent(undoneEvents)) {
		eventData = undoneEvents.find(event => event.id == searchParams.get('id'));
		showModal('event-modal');
	}

	$: procedureIsSelected = procedures => selectedProcedureIds.some(value => procedures.map(procedure => procedure.id).includes(value));

	const updateEvent = event => {
		fetchPut(`/api/private/events/${event.id}`, {
			completed: !event.completed,
			page: pagination.current_page,
			procedure_ids: selectedProcedureIds.length ? JSON.stringify(selectedProcedureIds) : null,
			except_completed: exceptCompleted ? true : null,
		}).then(response => ($transmitter = { ...$transmitter, events: response.events, pagination: response.events.length ? response.pagination : pagination }));
	};

	const goTo = page => {
		getFilteredEvents(page);
	};

	const defineTooltipText = event => {
		if (moment(event.expiration_date).diff(moment().startOf('day'), 'days') <= 0 && !event.completed) {
			return 'Просрочено';
		} else if (moment(event.expiration_date).diff(moment().startOf('day'), 'days') <= event.delay_before_reminder && !event.completed) {
			return `Осталось ${event.delay_before_reminder} дн. до истечения срока`;
		} else if (event.completed) {
			return 'Выполнено';
		} else {
			return 'Достаточно времени для выполнения';
		}
	};

	const defineBadgeColor = event => {
		if (event.completed) {
			return 'badge-secondary';
		} else if (moment(event.expiration_date).diff(moment().startOf('day'), 'days') <= 0 && !event.completed) {
			return 'badge-danger';
		} else if (moment(event.expiration_date).diff(moment().startOf('day'), 'days') <= event.delay_before_reminder && !event.completed) {
			return 'badge-warning';
		} else {
			return 'badge-primary';
		}
	};

	const showEventModal = event => {
		eventData = event;
		showModal('event-modal');
	};

	const toggleProcedures = arbitrManager => {
		selectedProcedureIds = procedureIsSelected(arbitrManager.procedures)
			? selectedProcedureIds.filter(id => !arbitrManager.procedures.map(procedure => procedure.id).includes(id))
			: [...selectedProcedureIds, ...arbitrManager.procedures.map(procedure => procedure.id)];
	};

	const getFilteredEvents = page => {
		let params = {
			procedure_ids: selectedProcedureIds.length ? JSON.stringify(selectedProcedureIds) : null,
			page: page,
		};

		if (exceptCompleted) {
			params['except_completed'] = true;
		}

		fetchGet(`/api/private/events/filter_by_attributes`, params).then(
			response => ($transmitter = { ...$transmitter, events: response.events, pagination: response.pagination }),
		);
	};

	onMount(() => {
		const ids = localStorage.getItem(keyCalenderSelected);

		if (isPresent(ids)) {
			selectedProcedureIds = ids.split(',').map(e => parseInt(e));
		}

		fetchGet('/api/private/procedures').then(
			response => (arbitrManagers = response.grouped_procedures.map(am => ({ ...am, procedures: am.procedures.filter(p => !p.completed) }))),
		);
		fetchGet('/api/private/events/undone_events').then(response => (undoneEvents = response.events));

		mounted = true;
	});

	$: if (mounted) {
		localStorage.setItem(keyCalenderSelected, selectedProcedureIds);
	}
</script>

<div class="row">
	<div class="col-12 calendar-menu">
		<button
			class="btn btn-sm btn-warning m-r-sm m-b-xs ml-2 ml-sm-0"
			on:click={() => showEventModal(null)}
			disabled={isBlank(arbitrManagers) || isBlank((arbitrManagers || []).map(am => am.procedures).flat())}
		>
			+ Задача
		</button>
		<button class="btn btn-sm btn-secondary m-r-sm m-b-sm calendar-filter-btn" on:click={() => (showFilter = !showFilter)}> Фильтр </button>
		<div class="float-right">
			<button class="btn btn-sm btn-default m-r-sm m-b-xs sync-with-kad-btn" on:click={() => (location.href = '/settings#sync-with-kad')}>
				Синхронизация с КАД.Арбитр
			</button>
			<div class="google-sync-button">
				<GoogleSyncButton />
			</div>
		</div>
		<Message message={$errorMessage} on:click={() => ($errorMessage = null)} />
	</div>
</div>
<div class="col-12 col-sm-9 float-left pl-0 pr-0 pr-sm-2 mb-5">
	<div class="overlay p-1 mb-2">
		{#if events}
			<table class="table table-hover table-mobile border-collapse_separate">
				<thead class="thead-light">
					<tr>
						{#each tableTitles as title}
							<th class="text-center p-w-xs">{title}</th>
						{/each}
					</tr>
				</thead>
				{#if events.length}
					<tbody class="text-center">
						{#each events as event (event.id)}
							<tr class="cursor_pointer" on:click={showEventModal(event)}>
								<td class="text-center">
									<input type="checkbox" class="cursor_pointer" checked={event.completed} on:click|stopPropagation={updateEvent(event)} />
								</td>
								<td class="text-center">
									<SvelteTooltip top tip={defineTooltipText(event)}>
										<span class="badge {defineBadgeColor(event)}">
											{moment(event.expiration_date).format('DD.MM.YYYY') || ''}
										</span>
									</SvelteTooltip>
								</td>
								<td class="text-left" class:text-muted={event.completed}>{event.name || ''}</td>
								<td class="text-center" width="250" class:text-muted={event.completed}>{event.procedure_name || ''}</td>
							</tr>
						{/each}
					</tbody>
				{/if}
			</table>
		{/if}
	</div>
	{#if pagination && pagination.total_pages > 1}
		<nav class="pagination footer-margin">
			<!--ul class='pagination justify-content-left'-->
			{#each pages as page}
				<div class="page-item cursor_pointer" role="button" tabindex="0" on:click={goTo(page)} on:keypress|stopPropagation>
					<span class="page-link" class:active-page={page === pagination.current_page}>{page}</span>
				</div>
			{/each}
			<!--/ul-->
		</nav>
	{/if}
</div>
<div class="col-12 col-sm-3 overlay float-right calendar-filter" class:hidden-filter={!showFilter}>
	<input id="checked-tasks" type="checkbox" bind:checked={exceptCompleted} />
	<label for="checked-tasks" class="ml-2"><h4>Скрывать выполненные</h4></label>
	<h4 class="mt-2">Выбрать АУ или процедуру</h4>
	{#if arbitrManagers}
		<button class="btn btn-secondary btn-block" disabled={!selectedProcedureIds.length} on:click={() => (selectedProcedureIds = [])}>Очистить</button>
		<ul class="list-group mb-3">
			{#each arbitrManagers as arbitrManager}
				<li class="list-group-item borderless">
					<input
						id={arbitrManager.arbitr_manager.id}
						type="checkbox"
						on:click={toggleProcedures(arbitrManager)}
						checked={procedureIsSelected(arbitrManager.procedures)}
					/>
					<label for={arbitrManager.arbitr_manager.id} class="mb-0 ml-2">{arbitrManager.arbitr_manager.text} ({arbitrManager.procedures.length})</label>
				</li>
				<ul class="list-group ml-4">
					{#each arbitrManager.procedures as procedure}
						<li class="list-group-item borderless">
							<input id={procedure.id} type="checkbox" bind:group={selectedProcedureIds} value={procedure.id} />
							<label for={procedure.id} class="mb-0 ml-2">{procedure.text}</label>
						</li>
					{/each}
				</ul>
			{/each}
		</ul>
	{/if}
</div>
<EventModal {eventData} {pagination} {selectedProcedureIds} {exceptCompleted} bind:requiredFieldsFilled bind:searchParams />

<style lang="scss">
	.active-page {
		background-color: #6c757d;
		border-color: #6c757d;
		color: #ffff;
	}

	li.borderless {
		border: 0 none;
		padding: 5px;
	}

	.google-sync-button {
		margin-bottom: 8px;
		margin-left: 8px;
		float: right;
		/*margin-top: -2px;*/
	}

	.pagination {
		display: block;

		.page-item {
			max-width: 5rem;
			float: left;
			margin-bottom: 0.5rem;
		}
	}

	.calendar-filter-btn {
		display: none;
	}
	.calendar-filter {
		display: block;
	}

	@media (max-width: 576px) {
		.calendar-filter-btn {
			display: inline-block;
		}
		.calendar-menu {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap-reverse;
		}
		.calendar-filter {
			position: absolute;
			overflow: auto;
			height: 70vh;
			z-index: 1000;
		}
		.google-sync-button {
			margin-bottom: 8px;
			margin-left: 8px;
			float: none;
		}
		.hidden-filter {
			display: none;
		}
		.sync-with-kad-btn {
			margin-left: 8px;
		}
	}
</style>
