<!-- @format -->
<script>
	import { isPresent } from '~/js/utils/tools.js';
	import { getContext } from 'svelte';

	export let id;
	export let name;
	export let value;
	export let klass;
	export let required;
	export let disabled;
	export let onchange = () => {};

	let context;

	$: if (required) {
		context = getContext('FormContext');
		context?.requiredFields?.update(arr => arr.concat([name]));
	}
</script>

<input {id} {name} {value} {disabled} class={klass} class:empty={!isPresent(value)} type="time" autocomplete="off" on:blur={onchange} />

<style>
	/* Хак из-за некорректного объявления класса empty в отчетах */
	.empty {
		padding-bottom: 6px;
		padding-left: 12px;
		padding-right: 12px;
		padding-top: 6px;
		height: calc(1.5em + 0.75rem + 2px);
	}
</style>
