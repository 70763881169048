/** @format */

import { get } from 'svelte/store';
import { procedure } from 'base_stores';
import { fetchGet, fetchPost, fetchPut, fetchDelete } from 'utils/fetch_helpers';

const apiBaseUrl = '/api/private';
const defaultParams = () => {
	const currentProcedure = get(procedure);
	return { procedure_id: currentProcedure?.id };
};

export const makeZeroPayment = payment_details => {
	const currentProcedure = get(procedure);
	fetchPost('/api/private/payments/make_zero_payment', { procedure_id: currentProcedure.id, payment_details }).then(response =>
		procedure.set(JSON.parse(response.procedure)),
	);
};

const childrenApi = {
	index: async () => fetchGet(`${apiBaseUrl}/children`, defaultParams()),
	show: async childId => fetchGet(`${apiBaseUrl}/children/${childId}`, defaultParams()),
	create: async params => fetchPost(`${apiBaseUrl}/children`, { child: params, ...defaultParams() }),
	update: async params => fetchPut(`${apiBaseUrl}/children/${params.id}`, { child: params, ...defaultParams() }),
	delete: async childId => fetchDelete(`${apiBaseUrl}/children/${childId}`, defaultParams()),
};

const dctMetaRecordsApi = {
	index: async () => fetchGet(`${apiBaseUrl}/meta_records`, defaultParams()),
	show: async id => fetchGet(`${apiBaseUrl}/meta_records/${id}`, defaultParams()),
	create: async params => fetchPost(`${apiBaseUrl}/meta_records`, { meta_record: params, ...defaultParams() }),
	update: async params => fetchPut(`${apiBaseUrl}/meta_records/${params.id}`, { meta_record: params, ...defaultParams() }),
	delete: async dctMetaRecordId => fetchDelete(`${apiBaseUrl}/meta_records/${dctMetaRecordId}`, defaultParams()),
};

const dctCorrespondentKindsApi = {
	index: async params => fetchGet(`${apiBaseUrl}/correspondent_kinds`, { ...params, ...defaultParams() }),
	show: async id => fetchGet(`${apiBaseUrl}/correspondent_kinds/${id}`, defaultParams()),
	create: async params => fetchPost(`${apiBaseUrl}/correspondent_kinds`, { correspondent_kind: params, ...defaultParams() }),
	update: async params => fetchPut(`${apiBaseUrl}/correspondent_kinds/${params.id}`, { correspondent_kind: params, ...defaultParams() }),
	delete: async dctCorrespondentKindId => fetchDelete(`${apiBaseUrl}/correspondent_kinds/${dctCorrespondentKindId}`, defaultParams()),
};

const proceduresApi = {
	show: async procedureId => fetchGet(`${apiBaseUrl}/procedures/${procedureId}`, { procedure_id: procedureId }),
};

const specialistsApi = {
	index: async () => fetchGet(`${apiBaseUrl}/specialists`, defaultParams()),
	show: async specialistId => fetchGet(`${apiBaseUrl}/specialists/${specialistId}`, defaultParams()),
	create: async params => fetchPost(`${apiBaseUrl}/specialists`, { specialist: params, ...defaultParams() }),
	update: async params => fetchPut(`${apiBaseUrl}/specialists/${params.id}`, { specialist: params, ...defaultParams() }),
	delete: async specialistId => fetchDelete(`${apiBaseUrl}/specialists/${specialistId}`, defaultParams()),
	specialistKindOptions: async () => fetchGet(`${apiBaseUrl}/specialists/get_kind_options`, defaultParams()),
	currentCreditorOptions: async () => fetchGet(`${apiBaseUrl}/specialists/get_current_creditor_options`, defaultParams()),
	paymentSourceOptions: async () => fetchGet(`${apiBaseUrl}/specialists/get_payment_source_options`, defaultParams()),
	paymentFrequencyOptions: async () => fetchGet(`${apiBaseUrl}/specialists/get_payment_frequency_options`, defaultParams()),
};

const spousesApi = {
	index: async () => fetchGet(`${apiBaseUrl}/spouses`, defaultParams()),
	show: async spouseId => fetchGet(`${apiBaseUrl}/spouses/${spouseId}`, defaultParams()),
	create: async params => fetchPost(`${apiBaseUrl}/spouses`, { spouse: params, ...defaultParams() }),
	update: async params => fetchPut(`${apiBaseUrl}/spouses/${params.id}`, { spouse: params, ...defaultParams() }),
	delete: async spouseId => fetchDelete(`${apiBaseUrl}/spouses/${spouseId}`, defaultParams()),
};

const insurancePoliciesApi = {
	index: async params => fetchGet(`${apiBaseUrl}/insurance_policies`, params),
	show: async insurancePolicyId => fetchGet(`${apiBaseUrl}/insurance_policies/${insurancePolicyId}`),
	create: async params => fetchPost(`${apiBaseUrl}/insurance_policies`, { insurance_policy: params }),
	update: async params => fetchPut(`${apiBaseUrl}/insurance_policies/${params.id}`, { insurance_policy: params }),
	delete: async insurancePolicyId => fetchDelete(`${apiBaseUrl}/insurance_policies/${insurancePolicyId}`),
};

export {
  childrenApi,
  dctMetaRecordsApi,
  dctCorrespondentKindsApi,
  proceduresApi,
  specialistsApi,
  spousesApi,
  insurancePoliciesApi,
};

export default {
	childrenApi,
	dctMetaRecordsApi,
	dctCorrespondentKindsApi,
	proceduresApi: proceduresApi,
	specialistsApi: specialistsApi,
	spousesApi: spousesApi,
	insurancePoliciesApi: insurancePoliciesApi,
};
