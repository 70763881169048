<!-- @format -->
<script>
	import { onMount, createEventDispatcher } from 'svelte';
	import { bankruptIsOrganization, transmitter, options } from 'base_stores';
	import { fetchGet, fetchPost, fetchPut, fetchDelete } from 'utils/fetch';
	import { isObject, isPresent, isBlank } from 'utils/tools';
	import { clearScans } from '~/svelte/_shared/scans_sets/scans_set.js'
	import { validate } from 'tools/validators';
	import { hideModal } from '~/svelte/components/modal.svelte';
	import Checkboxes from '~/svelte/components/ui/checkboxes.svelte';
	import Input from '~/svelte/components/ui/input.svelte';
	import Select2 from '~/svelte/components/ui/select2.svelte';
  import InputDaDataBank from '~/svelte/components/ui/input_dadata_bank.svelte'
	import Checkbox from '~/svelte/components/ui/checkbox.svelte';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import WysiwygTextbox from '~/svelte/components/ui/wysiwyg_textbox.svelte';
	import InputCurrency from '~/svelte/components/ui/input_currency.svelte';
	import PersonFields from './_person_fields.svelte';
	import OrganiztionFields from './_organization_fields.svelte';
	import { counterparty, actualRoles, types } from './_stores';
	import Case from 'case';
	import ScansSet from '~/svelte/_shared/scans_sets/_set_field.svelte'

	export let counterpartyId;
	export let fixedRoles = [];
	export let modalId = 'form-counterparty-modal';
	export let requiredFieldsFilled;
	export let disabled = false;

	export let handleSubmit;
	export let handleCancel;
	export let handleDelete;

	const collator = new Intl.Collator();
	const dispatch = createEventDispatcher();

	let isNewInstance
	let individualFields = null;
	let contractor = null;
	let skipVerificationRequired = false;
	let nameFieldFilled = false;
	let data = { counterparty: {} };
	let fixedType;
	let mounted = false;

	const bankSelected = ({ detail: rec }) => {
		if (isObject(rec)) {
			$counterparty.bank_bik = rec.bic;
			$counterparty.bank_rkc = rec.rkc;
			$counterparty.bank_cor_account = rec.correspondent_account;
			$counterparty.bank_name = rec.name.payment;
		} else {
			$counterparty.bank_bik = rec;
		}
	};

	const loadCounterparties = () => {
		let params = {};

		if ($counterparty.roles.includes('worker') || fixedRoles.includes('worker')) {
			params = { filters: { roles: { only: 'worker' } } };
		} else if (bankruptIsOrganization) {
			params = { filters: { roles: { except: ['worker'] } } };
		}

		fetchGet('/api/private/counterparties', params).then(response => {
			$transmitter = { ...$transmitter, counterparties: response.counterparties.sort((a, b) => collator.compare(a.name, b.name)) };
		});
	};

	$: method = counterpartyId ? fetchPut : fetchPost;

	$: requiredFieldsFilled = $counterparty.contractor_type && isPresent($counterparty.roles) && isPresent(nameFieldFilled);

	$: if (mounted) {
		data = {
			counterparty: {
				...$counterparty,
				contractor_type: $counterparty.contractor_type == 'Individual_Entrepreneur' ? 'Person' : $counterparty.contractor_type,
			},
			...individualFields,
		};
	}

	$: if (!counterpartyId) {
		counterparty.clear();
		contractor = null;
	}

	$: if (counterpartyId && counterpartyId !== $counterparty.id) {
		fetchGet(`/api/private/counterparties/${counterpartyId}`).then(response => {
			const contractorType = response.counterparty.contractor_type; // с маленькой буквы
			contractor = { [contractorType]: response.counterparty.contractor };
			$counterparty = response.counterparty;
			$counterparty.contractor_type = contractor[contractorType].individual_entrepreneur ?
				'Individual_Entrepreneur' :
				Case.capital(response.counterparty.contractor_type, '_'); // с большой буквы
			delete $counterparty.contractor;
		});
	}

	$: if ($counterparty.roles.includes('kdl')) {
		$counterparty.additions.kdl = $counterparty.additions.kdl || {};
	} else {
		delete $counterparty.additions['kdl'];
	}

	$: if ($counterparty.roles.includes('worker')) {
		$counterparty.additions.worker = $counterparty.additions.worker || {};

		if (!$counterparty.additions.worker.salary_arrears) {
			delete $counterparty.additions.worker['arrears_before_acceptance'];
			delete $counterparty.additions.worker['arrears_after_acceptance'];
		}
	}

	$: if ($counterparty.roles.includes('declarer')) {
		$counterparty.roles = [...new Set([...$counterparty.roles, 'creditor'])];
	}

	$: roleOptions = $actualRoles?.map(role =>
		fixedRoles.includes(role.id) || (role.id == 'creditor' && $counterparty.roles.includes('declarer')) ? { ...role, fixed: true } : role,
	);

	$: formFields = {
		contractorType: {
			disabled: counterpartyId || disabled,
			required: true,
			withSearch: false,
			attribute: 'contractor_type',
			model: 'counterparty',
			label: 'Вид контрагента',
			options: [{ id: '', text: '' }, ...$types.map(t => ({ id: t.id, text: t.text }))],
			placeholder: 'Выбрать вид контрагента',
		},
		roles: {
			required: true,
			attribute: 'roles',
			model: 'counterparty',
			label: $counterparty.roles.includes('worker') ? 'Роль' : 'Роли контрагента',
			options: roleOptions,
			errors: data?.counterparty?.errors?.roles,
			disabled,
		},
		subsidiaryResponsibilityCheck: {
			label: 'Привлекается к субсидиарной ответственности',
			text: 'да, привлекается',
			disabled,
		},
		submissionClaimsDate: {
			model: 'counterparty',
			label: 'Дата предъявления требований',
			maskOptions: { mask: Date },
			placeholder: 'дд.мм.гггг',
			errors: data?.counterparty?.errors?.filingClaimsDate,
			disabled,
		},
		amountOfClaims: {
			model: 'counterparty',
			attribute: 'amount_of_claims',
			label: 'Сумма требований, руб.',
			placeholder: '0,00',
			type: 'number',
		},
		salaryArrearsCheck: {
			label: 'Есть задолженность по зарплате',
			text: 'да, имеется',
			disabled,
		},
		arrearsBeforeAcceptance: {
			model: 'counterparty',
			attribute: 'arrears_before_acceptance',
			label: 'Сумма задолженности до принятия судом заявления о банкротстве, руб.',
			placeholder: '0,00',
			type: 'number',
		},
		arrearsAfterAcceptance: {
			model: 'counterparty',
			attribute: 'arrears_after_acceptance',
			label: 'Сумма задолженности после принятия судом заявления о банкротстве, руб.',
			placeholder: '0,00',
			type: 'number',
		},
		documentArbitraryText: {
			label: 'Результат',
			disabled,
		},
		bankBik: {
			attribute: 'bank_bik',
			model: 'counterparty',
			label: 'БИК банка',
			// maskOptions: { mask: '000000000' },
			placeholder: 'Введите БИК, наименование или ИНН банка',
			errors: data?.counterparty?.errors?.bank_bik,
			disabled,
		},
		bankName: {
			attribute: 'bank_name',
			model: 'counterparty',
			label: 'Наименование банка',
			placeholder: 'ПАО “Банк”',
			disabled,
		},
		kdlPosition: {
			attribute: 'position',
			model: 'counterparty',
			label: 'Должность КДЛ',
			placeholder: 'Учредитель',
			disabled,
		},
		bankRkc: {
			attribute: 'bank_rkc',
			model: 'counterparty',
			label: 'РКЦ банка',
			placeholder: 'ГУ Банка России по ЦФО',
			disabled,
		},
		bankCorAccount: {
			attribute: 'bank_cor_account',
			model: 'counterparty',
			label: 'Корреспондентский счёт',
			maskOptions: { mask: '00000000000000000000' },
			placeholder: '12345678901234567890',
			errors: data?.counterparty?.errors?.bank_cor_account,
			disabled,
		},
		bankAccount: {
			attribute: 'bank_account',
			model: 'counterparty',
			label: 'Расчётный счёт',
			maskOptions: { mask: '00000000000000000000' },
			placeholder: '12345678901234567890',
			errors: data?.counterparty?.errors?.bank_account,
			disabled,
		},
		dismissalOrderDate: {
			attribute: 'dismissal_order_date',
			model: 'counterparty',
			label: 'Дата приказа об увольнении',
			maskOptions: { mask: Date },
			placeholder: 'дд.мм.гггг',
			errors: data?.counterparty?.errors?.dismissal_order_date,
			disabled,
		},
	};

	$: filledRequiredFields = isPresent($counterparty.contractor_type) && isPresent($counterparty.roles) && (
    isPresent(individualFields?.organization?.short_name) || 
    isPresent(individualFields?.person?.surname) && isPresent(individualFields?.person?.name) && isPresent(individualFields?.person?.patronymic)
  )

  $: if (isBlank(counterpartyId) && filledRequiredFields) { secretlyСreate() }

  const secretlyСreate = async () => {
    const response = await fetchPost('/api/private/counterparties', data)
    $counterparty.id = response?.counterparty_id
    $counterparty.scans_set = { id: null, proprietor_type: 'Counterparty', proprietor_id: $counterparty.id, scans: [] }
    counterpartyId = response?.counterparty_id
  }

  const refreshScansSet = ({detail: scansSet}) => {
    $transmitter.counterparties = $transmitter.counterparties.map(s => s.id == counterpartyId ? { ...s, scans_set: scansSet } : s)
    $counterparty.scans_set = scansSet
  }

	handleSubmit = async () => {
		let noErrorMarks = [];

		for (let model in data) {
			for (let attribute in data[model]) {
				if (['errors', 'reg_address_full'].includes(attribute)) {
					continue;
				}

				const errors = validate(model, attribute, data[model][attribute], skipVerificationRequired);
				noErrorMarks.push(!errors);

				if (errors) {
					data[model]['errors'] = data[model]['errors'] ? { ...data[model]['errors'], [attribute]: errors } : { [attribute]: errors };
				} else {
					if (data[model]['errors']) {
						delete data[model]['errors'][attribute];
					}
				}
			}
		}

		if (noErrorMarks.every(mark => mark)) {
      let params = data
      for (let model in params) { delete(params[model]['errors']) }

      const response = await method(`/api/private/counterparties${counterpartyId ? `/${counterpartyId}` : ''}`, data)

      const scansSet = await clearScans($counterparty.scans_set, 'deleted')
      $transmitter.counterparties = $transmitter.counterparties.map(s => s.id == counterpartyId ? { ...s, scans_set: scansSet } : s)

      dispatch((isNewInstance ? 'create' : 'update'), response.counterparty_id || counterpartyId)
      hideModal(modalId)
			$options.organization = undefined;
			counterparty.clear();
			fixedRoles = [];
			counterpartyId = null;
			individualFields = null;
			contractor = null;
    } else {
      return false;
    }
	};

	handleCancel = () => {
		if (isNewInstance) {
			if (isPresent(counterpartyId)) { handleDelete() }
		} else {
			clearScans($counterparty.scans_set, 'new')
		}

		counterparty.clear();
		fixedRoles = [];
		counterpartyId = null;
		individualFields = null;
		contractor = null;
	}

	handleDelete = () =>
		fetchDelete(`/api/private/counterparties/${counterpartyId}`)
			.then(() => {
				$transmitter.counterparties = $transmitter.counterparties.filter(с => с.id != counterpartyId)
				dispatch('delete')
			});
			//.catch(errors => console.log('errors: ' + JSON.stringify(errors)))

	onMount(() => {
		isNewInstance = isBlank(counterpartyId)

		if (isBlank($transmitter?.counterparties)) {
			loadCounterparties();
		}

		if (fixedRoles?.some(role => ['bank', 'stockbroker', 'cryptoex'].includes(role))) {
			fixedType = 'Organization';
		} else if (fixedRoles?.some(role => ['kdl', 'worker'].includes(role))) {
			fixedType = 'Person';
		}

		$counterparty = {
			...$counterparty,
			contractor_type: fixedType,
			roles: isPresent(fixedRoles) ? fixedRoles : [],
		};

		if (fixedRoles?.some(role => ['bank', 'stockbroker'].includes(role))) {
			$counterparty.bank_name = null;
		} else {
			$counterparty.bank_rkc = null;
		}

		mounted = true;
	});
</script>

{#if isBlank(fixedType)}
	<Select2 {...formFields.contractorType} bind:value={$counterparty.contractor_type} />
{/if}
{#if $counterparty.contractor_type}
	<Checkboxes {...formFields.roles} columnCount=2 bind:value={$counterparty.roles} />
{/if}
{#if $counterparty.contractor_type == 'Person' || $counterparty?.contractor_type == 'Individual_Entrepreneur'}
	<PersonFields {contractor} {disabled} errors={data?.person?.errors} bind:individualFields bind:nameFieldFilled />
{/if}
{#if $counterparty.contractor_type == 'Organization'}
	<OrganiztionFields {contractor} {disabled} errors={data?.organization?.errors} bind:individualFields bind:nameFieldFilled />
{/if}
{#if $counterparty.contractor_type && !$counterparty.roles.includes('cryptoex')}
	<InputDaDataBank {...formFields.bankBik} bind:value={$counterparty.bank_bik} on:change={bankSelected} />
	{#if $counterparty.roles.includes('bank')}
		<Input {...formFields.bankRkc} bind:value={$counterparty.bank_rkc} />
		<Input {...formFields.bankCorAccount} bind:value={$counterparty.bank_cor_account} />
	{:else}
		<Input {...formFields.bankName} bind:value={$counterparty.bank_name} />
		<Input {...formFields.bankCorAccount} bind:value={$counterparty.bank_cor_account} />
		<Input {...formFields.bankAccount} bind:value={$counterparty.bank_account} />
	{/if}
{/if}
{#if $counterparty.roles.includes('worker')}
	<Checkbox {...formFields.salaryArrearsCheck} bind:checked={$counterparty.additions.worker.salary_arrears} />
	{#if $counterparty.additions.worker.salary_arrears}
		<InputCurrency {...formFields.arrearsBeforeAcceptance} bind:value={$counterparty.additions.worker.arrears_before_acceptance} />
		<InputCurrency {...formFields.arrearsAfterAcceptance} bind:value={$counterparty.additions.worker.arrears_after_acceptance} />
	{/if}
	<Datepicker {...formFields.dismissalOrderDate} bind:value={$counterparty.additions.worker.dismissal_order_date} />
{/if}
{#if $counterparty.roles.includes('kdl')}
	<Input {...formFields.kdlPosition} bind:value={$counterparty.additions.kdl.position} />
	<Checkbox {...formFields.subsidiaryResponsibilityCheck} bind:checked={$counterparty.additions.kdl.is_subsidiary_responsibility} />
	{#if $counterparty.additions.kdl.is_subsidiary_responsibility}
		<Datepicker {...formFields.submissionClaimsDate} bind:value={$counterparty.additions.kdl.submission_claims_date} />
		<InputCurrency {...formFields.amountOfClaims} bind:value={$counterparty.additions.kdl.amount_of_claims} />
		<WysiwygTextbox {...formFields.documentArbitraryText} bind:value={$counterparty.additions.kdl.result} />
	{/if}
{/if}
{#if $counterparty.contractor_type}
  <ScansSet label='Документы', scansSet={$counterparty.scans_set} disabled={!filledRequiredFields} on:changed={refreshScansSet}/>
{/if}
