<!-- @format -->
<script>
	import { tick } from 'svelte';
	import { fetchPut } from 'utils/fetch';
	import { isPresent } from 'utils/tools';
	import { autosaveStatus } from 'base_stores';

	export let tags = [];
	export let kind;
	export let kindId;

	let newTag;
	let tagInput;
	let tagInputStyles = 'display:none;';

	const updateTagsToKind = () => {
		$autosaveStatus = null;
		let params = { kind: kind, kind_id: kindId, texts: tags };

		fetchPut('/api/private/tags/update_kind', params).then(result => {
			tags = result.tags;
			$autosaveStatus = 'saved';
		});
	};

	const deleteTag = deletedTag => {
		tagInputStyles = 'display:none;';
		const tagIndex = tags.findIndex(tag => tag === deletedTag);
		tags = tags.filter((_, index) => index !== tagIndex) || [];
		updateTagsToKind();
	};

	async function addTag() {
		await tick();
		tagInputStyles = 'display:none;';
		if (isPresent(newTag)) {
			if (tags.findIndex(tag => tag === newTag) === -1) {
				tags = [...tags, newTag];
				updateTagsToKind();
			}
			newTag = '';
		}
	}

	const handleKeyPress = event => {
		if (event.key === 'Enter') {
			addTag();
		}
	};

	async function inputFocus() {
		tagInputStyles = '';
		await tick();
		tagInput.focus();
	}
</script>

<div class="form-group row">
	<label class="col-sm-4 col-lg-3 col-xl-4 col-form-label" for="">Теги</label>
	<div class="tag-list" role="button" tabindex="0" on:click={inputFocus} on:keypress|stopPropagation>
		{#each tags as tag}
			<div class="tag">
				{tag}
				<div
					class="tag-button delete"
					role="button"
					tabindex="0"
					on:click={e => {
						e.stopPropagation();
						deleteTag(tag);
					}}
					on:keypress|stopPropagation
				>
					×
				</div>
			</div>
		{/each}
		<input bind:this={tagInput} bind:value={newTag} class="tag-input" on:blur={addTag} on:keydown={handleKeyPress} style={tagInputStyles} type="text" />
	</div>
</div>

<style lang="scss">
	.row {
		flex-wrap: nowrap;
	}
	.tag-list {
		position: relative;
		width: 100%;
		border: 1px solid #1ab394;
		margin-left: 15px;
		margin-right: 15px;
		display: flex;
		border-radius: 3px;
		flex-wrap: wrap;
		/*-webkit-transition: all 0.2s 0.01s ease-in;*/
	}

	.tag {
		display: flex;
		background-color: #e1e1e1;
		font-size: 13px;
		color: #100f0f;
		border-radius: 3px;
		margin: 5px;
		padding: 3px 3px 3px 8px;
	}

	.tag-button {
		margin-left: 5px;
		padding-left: 5px;
		padding-right: 5px;
		background-color: #e1e1e1;
		cursor: pointer;
		border-radius: 3px;
		/*-webkit-transition: all 0.2s 0.01s ease-in;*/
	}

	.tag-button:hover {
		background-color: #fff;
	}

	.tag-input {
		padding: 3px 3px 3px 8px;
		margin: 5px;
		border-radius: 3px;
		min-width: 30px;
		max-width: 60%;
		width: 100%;
		background-color: #fff;
		border: none;
		outline: none;
	}
</style>
