<script>
	import ScansSet from './_set_block.svelte'

  export let attribute = 'scans'
  export let label
  export let disabled
  export let scansSet = {}
  export let labelCss = 'col-sm-4 col-lg-3 col-xl-4 col-form-label'
  export let divCss = 'col-sm-8 col-lg-9 col-xl-8 m-t-xs'

  const inputId = `input-${scansSet.proprietor_type}-${scansSet.proprietor_id}-${attribute}`
</script>

<div class='form-group row'>
  <label for={inputId} class={labelCss}>
    {label}
  </label>
  <div class={divCss}>
    <ScansSet {scansSet} {disabled} on:changed/>
  </div>
</div>
