<!-- @format -->
<script>
	import { transmitter, autosaveStatus } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { onMount } from 'svelte';
	import { fetchGet, fetchPost, fetchPut } from 'utils/fetch';
	import { isBlank, isPresent } from 'utils/tools';
	import Header from '../components/header.svelte';
	import AutosaveDatepicker from '../components/ui/autosave_datepicker.svelte';
	import Dashboard from './dashboard.svelte';
	import PayoutModal from '~/svelte/payouts/_modal.svelte';
	import AmendementModal from '~/svelte/amendements/_modal.svelte';
	import CreditorsRequirementModal from './_modal.svelte';
	import CreditorsRequirementsTable from './table.svelte';
	import moment from 'moment';

	let procedure;
	let mounted = false;
	let procedureId = null;
	let relevanceDate;
	let previousPublicationNewspaperDate;

	const loadCreditorsRequirements = () => {
		if (procedureId && relevanceDate) {
			mounted = false;
			fetchGet('/api/private/creditors_requirements', { date: relevanceDate })
				.then(response => {
					$transmitter = { ...$transmitter, creditors_requirements: response.creditors_requirements };
				})
				.then(() => (mounted = true));
		}
	};

	$: loadCreditorsRequirements(relevanceDate);

	$: procedure = $transmitter && $transmitter.procedure;
	$: procedureId = procedure && procedure.id;
	$: requirementsReestr = ($transmitter && $transmitter.requirements_reestr) || requirementsReestr;
	$: requirementsReestrId = requirementsReestr && requirementsReestr.id;
	$: reestrClosingDate = procedure && procedure.phase === 'supervision' ? { delay: { days: 30 }, text: '30 дней' } : { delay: { months: 2 }, text: '2 месяца' };

	$: reestrClosingDateByPublicationNewspaperDate =
		procedure && procedure.publication_newspaper_date
			? moment(procedure && procedure.publication_newspaper_date)
					.add(reestrClosingDate.delay)
					.format('YYYY-MM-DD')
			: null;

	$: closingDate = (requirementsReestr && requirementsReestr.closing_date) || reestrClosingDateByPublicationNewspaperDate;

	$: formFields = {
		publicationFedresursDate: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'publication_fedresurs_date',
			model: 'procedure',
			url: '/api/private/procedures',
			id: procedureId,
			label: 'Дата публикации в «Федресурс»',
			maskOptions: { mask: Date },
			placeholder: 'дд.мм.гггг',
			value: procedure && procedure.publication_fedresurs_date,
			hintData: {
				url: `/procedures/${procedureId}`,
				linkText: 'Процедура',
				consumerNew: isBlank(procedure && procedure.publication_fedresurs_date),
				resource: procedure,
				resourceProp: 'publication_fedresurs_date',
			},
		},
		publicationNewspaperDate: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'publication_newspaper_date',
			model: 'procedure',
			url: '/api/private/procedures',
			id: procedureId,
			label: 'Дата публикации в «Коммерсантъ»',
			maskOptions: { mask: Date },
			placeholder: 'дд.мм.гггг',
			value: procedure && procedure.publication_newspaper_date,
			hintData: {
				url: `/procedures/${procedureId}`,
				linkText: 'Процедура',
				consumerNew: isBlank(procedure && procedure.publication_newspaper_date),
				resource: procedure,
				resourceProp: 'publication_newspaper_date',
			},
		},
		reestrClosingDate: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'closing_date',
			model: 'requirements_reestr',
			url: '/api/private/requirements_reestr',
			id: requirementsReestrId,
			childModel: 'procedure',
			childId: procedureId,
			label: 'Дата закрытия реестра',
			maskOptions: { mask: Date },
			placeholder: 'дд.мм.гггг',
			value: closingDate,
			forceSave: requirementsReestr && !requirementsReestr.closing_date,
			hintData:
				closingDate === reestrClosingDateByPublicationNewspaperDate
					? { linkText: `"Дата публикации в Коммерсантъ" + ${reestrClosingDate.text}` }
					: { freeMessage: 'Изменено пользователем' },
		},
	};
	$: if (
		requirementsReestr &&
		procedure &&
		isPresent(procedure.publication_newspaper_date) &&
		procedure.publication_newspaper_date !== previousPublicationNewspaperDate
	) {
		if (
			previousPublicationNewspaperDate &&
			moment(previousPublicationNewspaperDate).add(reestrClosingDate.delay).format('YYYY-MM-DD') === requirementsReestr.closing_date
		) {
			updateClosingDate();
		}
		previousPublicationNewspaperDate = procedure.publication_newspaper_date;
	} else {
		previousPublicationNewspaperDate = procedure.publication_newspaper_date;
	}

	const updateClosingDate = () => {
		let newClosingDate = moment(procedure.publication_newspaper_date).add(reestrClosingDate.delay).format('YYYY-MM-DD');

		if (moment(newClosingDate, moment.ISO_8601, true).isValid()) {
			let params = {
				requirements_reestr: { closing_date: newClosingDate },
				procedure_id: procedureId,
			};
			fetchPut(`/api/private/requirements_reestr/${requirementsReestrId}`, params).then(() => {
				requirementsReestr.closing_date = newClosingDate;
			});
		}
	};

	onMount(async () => {
		$autosaveStatus = null;

		procedure = $transmitter && $transmitter.procedure;
		procedureId = procedure && procedure.id;
		requirementsReestr = procedure && procedure.requirements_reestr;

		if (!requirementsReestr) {
			const params = {
				requirements_reestr: {
					publication_fedresurs_date: procedure && procedure.publication_fedresurs_date,
					publication_newspaper_date: procedure && procedure.publication_newspaper_date,
					closing_date: reestrClosingDateByPublicationNewspaperDate,
				},
			};

			fetchPost('/api/private/requirements_reestr', params)
				.then(response => {
					if (JSON.stringify(response) !== '{}') {
						$autosaveStatus = 'saved';
					}
					$transmitter = { ...$transmitter, requirements_reestr: response.requirements_reestr };
					return;
				})
				.then(() => (mounted = true))
				.catch(_errors => ($autosaveStatus = 'not_saved'));
		}
	});

	let creditorRequirementModal;
	let payoutModal;
	let amendementModal;

	const openCreditorsRequirementModal = id => (id ? creditorRequirementModal.editItem(id) : creditorRequirementModal.newItem());
	const openPayoutModal = id => (id ? payoutModal.editItem(id) : payoutModal.newItem({ item: { kind: 'rtk' } }));
	const payoutCreated = () => loadCreditorsRequirements(relevanceDate);
	const amendementCreated = () => loadCreditorsRequirements(relevanceDate);
	const openAmendementModal = id => (id ? amendementModal.newItem({ queryParams: { creditors_requirement_id: id } }) : amendementModal.newItem());
	const creditorRequirementDeleted = () => loadCreditorsRequirements(relevanceDate);
</script>

<Header><span slot="name">Реестр требований кредиторов</span></Header>
<div class="overlay creditor_requirements_dates">
	<AutosaveDatepicker {...formFields.publicationFedresursDate} />
	<AutosaveDatepicker {...formFields.publicationNewspaperDate} />
	<AutosaveDatepicker {...formFields.reestrClosingDate} />
</div>
<div class="m-b-xl p-h-sm footer-margin">
	<Dashboard {openCreditorsRequirementModal} {openPayoutModal} bind:relevanceDate />
	<CreditorsRequirementsTable {openCreditorsRequirementModal} {openAmendementModal} bind:relevanceDate bind:mounted />
</div>

<CreditorsRequirementModal bind:this={creditorRequirementModal} on:delete={creditorRequirementDeleted} />

<PayoutModal bind:this={payoutModal} on:create={payoutCreated} />

<AmendementModal bind:this={amendementModal} on:create={amendementCreated} />

<style>
	.creditor_requirements_dates {
		width: 70%;
	}

	@media (max-width: 576px) {
		.creditor_requirements_dates {
			width: 100%;
		}
	}
</style>
