<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { isPresent, isBlank, toArray, formatSum } from '~/js/utils/tools.js';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import Form from '~/svelte/components/ui/form/_form.svelte';
	import Field from '~/svelte/components/ui/form/_form_field.svelte';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';
	import InputTime from '~/svelte/components/ui/input_time.svelte';
	import InputMoney from '~/svelte/components/ui/input_money.svelte'
	import ScansSet from '~/svelte/_shared/scans_sets/_set_field.svelte'

	const dispatch = createEventDispatcher();

	export let scansSetChanged = false
	export let isNewInstance
	export let formOptions;
	export let isCompleted;
	export const submitForm = () => formControl.submitForm();
	export const deleteItem = () => formControl.deleteItem();
	export const getItem = () => formControl.getItem();
	export const setItem = changes => formControl.setItem(changes);
	export const getData = () => data;
	export const setData = changes => (data = { ...data, ...changes });

	let formControl;
	let form, errorsBase, handleChange, updateValidateField
	let data = {};
	let votes = 0;
	let rtk_voting_sum = 0;
	let rtk_unsecured_voting_sum = 0;

	const setInitialazedForm = instance => {
		form = instance.form;
		errorsBase = instance.errorsBase;
		handleChange = instance.handleChange;
		updateValidateField = instance.updateValidateField;
	};

	const setDictionariesValues = values => {
		data = { ...values };
		data.counterparties = [{ value: -1, label: 'Добавить участника', action: true }, ...data.counterparties];
	};

	const setSubmitParams = () => {
		const params = {};
		return params;
	};

	const handleNewCounterparty = () => dispatch('newCounterparty');
	const handleSelectCounterparty = ({ detail: e }) => {
		$form.voting_sum = e.voting_sum;
		$form.unsecured_voting_sum = e.unsecured_voting_sum;
		updateValidateField('voting_sum', $form.voting_sum);
		updateValidateField('unsecured_voting_sum', $form.unsecured_voting_sum);
	};

	$: if (isPresent($form)) {
		const total_voting_sum = 1 * formOptions.meeting.voting_sum;
		votes = total_voting_sum > 0 ? Math.round(((1 * $form.voting_sum) / total_voting_sum) * 10000) / 100 : 0;
		const counterparty = toArray(data.counterparties).find(e => e.value == $form.counterparty_id);
		if (isPresent(counterparty)) {
			rtk_voting_sum = counterparty.voting_sum;
			rtk_unsecured_voting_sum = counterparty.unsecured_voting_sum;
		}
	}

	$: formOptions.queryParams = { role: 'worker' }

	$: filledRequiredFields = $form &&
		isPresent($form.counterparty_id) && Number.isInteger(+$form.counterparty_id) &&
		(
			$form.kind != 'representative' ||
			$form.kind == 'representative' && isPresent($form.representative_name) && isPresent($form.representative_doc)
		)

	let creating = false
	$: if (isBlank($form?.id) && filledRequiredFields) {
		if (!creating) { secretlyСreate() }
		creating = true
	}

	const secretlyСreate = async () => {
		const response = await submitForm()
		$form = response?.item
		creating = false
	}

	$: if (isPresent($form) && !isNewInstance) { isNewInstance = isBlank($form?.id) }
</script>

<Form
	bind:this={formControl}
	bind:isCompleted
	formOptions={{ ...formOptions, model: 'meeting_participant', controller: 'meetings/' + formOptions.meeting.id + '/meeting_participants' }}
	{setDictionariesValues}
	{setSubmitParams}
	{setInitialazedForm}
>
	<!-- Участник -->
	<Field required={true} name="counterparty_id" label="Участник" disabled={formOptions.readonly}>
		<Select
			name="counterparty_id"
			items={data.counterparties}
			value={$form.counterparty_id}
			onchange={handleChange}
			on:action={handleNewCounterparty}
			on:select={handleSelectCounterparty}
			isDisabled={formOptions.readonly}
			containerClasses="form-control"
			addPlaceList={true}
			placeholder="Выбрать участника"
		></Select>
	</Field>
	<!-- Время регистрации -->
	{#if formOptions.meeting.form == 'facetoface'}
		<Field time={true} name="time_at" label="Время регистрации" disabled={formOptions.readonly}>
			<InputTime name="time_at" value={$form.time_at} onchange={handleChange} disabled={formOptions.readonly} klass="form-control" />
		</Field>
	{/if}
	<!-- Статус участника -->
	{#if formOptions.meeting.kind == 'creditor'}
		<Field required={true} name="status" label="Статус участника" disabled={formOptions.readonly}>
			<Select
				name="status"
				items={data.statuses}
				value={$form.status}
				onchange={handleChange}
				isDisabled={formOptions.readonly}
				containerClasses="form-control"
				placeholder="Выбрать статус"
			></Select>
		</Field>
	{/if}
	<!-- Участие в собрании -->
	<Field name="kind" label="Участие в собрании" disabled={formOptions.readonly}>
		{#each toArray(data.kinds) as option}
			<label class="d-flex">
				<input type="radio" bind:group={$form.kind} class="align-self-center m-r-xs" value={option.value} disabled={formOptions.readonly} />
				{option.label}
			</label>
		{/each}
	</Field>

	{#if $form.kind == 'representative'}
		<!-- Представитель участника -->
		<Field required={true} name="representative_name" label="Представитель участника" disabled={formOptions.readonly}>
			<input
				name="representative_name"
				class="form-control"
				bind:value={$form.representative_name}
				on:change={handleChange}
				disabled={formOptions.readonly}
				placeholder="Иванов И.И."
			/>
		</Field>
		<!-- Полномочия представителя -->
		<Field required={true} name="representative_doc" label="Полномочия представителя" disabled={formOptions.readonly}>
			<textarea
				name="representative_doc"
				rows="2"
				class="form-control"
				bind:value={$form.representative_doc}
				on:change={handleChange}
				disabled={formOptions.readonly}
				placeholder="Доверенность от 00.00.0000 г."
			/>
		</Field>
	{/if}
	<!-- Размер требований участника с учетом залоговых, руб. -->
	{#if formOptions.meeting.kind == 'creditor'}
		<Field
			required={formOptions.meeting.form == 'absentee'}
			name="voting_sum"
			label="Размер требований участника с учетом залоговых, руб."
			short={true}
			disabled={formOptions.readonly}
		>
			<InputMoney name="voting_sum" value={$form.voting_sum} onchange={handleChange} disabled={formOptions.readonly} klass="form-control text-right" />
			{#if isPresent($form.counterparty_id)}
				<span class="form-text text-muted m-b-none m-l-xs">По данным РТК = {formatSum(rtk_voting_sum)}</span>
			{/if}
		</Field>
	{/if}
	<!-- Размер требований участника без учета залоговых, руб. -->
	{#if formOptions.meeting.kind == 'creditor'}
		<Field
			required={formOptions.meeting.form == 'absentee'}
			name="unsecured_voting_sum"
			label="Размер требований участника без учета залоговых, руб."
			short={true}
			disabled={formOptions.readonly}
		>
			<InputMoney
				name="unsecured_voting_sum"
				value={$form.unsecured_voting_sum}
				onchange={handleChange}
				disabled={formOptions.readonly}
				klass="form-control text-right"
			/>
			{#if isPresent($form.counterparty_id)}
				<span class="form-text text-muted m-b-none m-l-xs">По данным РТК = {formatSum(rtk_unsecured_voting_sum)}</span>
			{/if}
		</Field>
	{/if}
	<!-- Количество голосов, % -->
	{#if formOptions.meeting.kind == 'creditor'}
		<Field name="votes" label="Количество голосов, %" short={true} disabled={true}>
			<div class="form-control text-right" class:disabled={true}>
				{formatSum(votes)}
			</div>
		</Field>
	{/if}
	<ScansSet
		label='Документы',
		scansSet={$form.scans_set}
		disabled={!filledRequiredFields}
		on:changed={({detail: scansSet}) => {
			$form.scans_set = scansSet
			scansSetChanged = true
		}}
	/>
	<ErrorMessage message={ $errorsBase } on:click={() => $errorsBase = null}/>
</Form>
