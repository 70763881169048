<!-- @format -->
<script>
	import { getContext } from 'svelte';
	import { isPresent, isBlank, toArray } from 'utils/tools';
	import Field from '~/svelte/components/ui/form/_form_field.svelte';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';

  export let kinds
  export let disabled
  export let level = 0
  export let filled

  let subkindFilled

	const { form, handleChange } = getContext('FormContext').formInstance;

  $: parentLevelKindId = $form[`kind_id${level - 1}`]
  $: currentLevelKindId = $form[`kind_id${level}`]
  $: filled = isPresent(currentLevelKindId) && (hasSubkinds ? subkindFilled : true)

	$: hasSubkinds = isPresent(currentLevelKindId && toArray(kinds).find(k => String(k.parent_id) === String(currentLevelKindId)));

	$: unsortedItems = parentLevelKindId
		? toArray(kinds).filter(k => String(k.parent_id) === String(parentLevelKindId))
		: toArray(kinds).filter(k => !k.parent_id && !['debit', 'money'].includes(k.code));
	$: items = unsortedItems.sort((a, b) => a.sequence - b.sequence);

	$: kind = items.find(k => String(k.value) === String(currentLevelKindId));
	$: kindParent = toArray(kinds).find(k => String(k.value) === String(parentLevelKindId));
	$: label = (isPresent(kindParent) ? kindParent.form_subkinds_label : kind && kind.form_label) || 'Вид имущества';
	$: placeholder = (isPresent(kindParent) ? kindParent.form_subkinds_placeholder : kind && kind.form_placeholder) || 'Выбрать вид';

	$: if (level === 0 && isBlank($form.kind_id0)) {
		$form = setKind0();
	}

	const setKind0 = () => {
		return { ...$form, kind_id0: $form.kind_id || (isPresent(items) && items[0].id) };
	};

	$: if (
		(isPresent(kind) && isPresent(kindParent) && String(kind.parent_id) !== String(parentLevelKindId)) ||
		(isPresent(items) && isBlank(kind) && currentLevelKindId)
	) {
		nullifyKind();
	}

	const nullifyKind = () => ($form[`kind_id${level}`] = null);
</script>

<Field name={`kind_id${level}`} {label} required={true}>
	<Select
		isDisabled={disabled}
		name={`kind_id${level}`}
		{items}
		value={currentLevelKindId}
		onchange={handleChange}
		containerClasses="form-control"
		{placeholder}
	></Select>
</Field>
{#if hasSubkinds}
  <svelte:self level={level + 1} {disabled} {kinds} {handleChange} bind:filled={subkindFilled}/>
{/if}
