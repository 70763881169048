<!-- @format -->
<script>
	export let switchValue = true;
	export let val1 = 'Форма';
	export let val2 = 'Документ';
</script>

<div class="switch-btn d-block d-sm-none">
	<div class="btn-group btn-group-toggle">
		<label class="btn btn-secondary" class:active={switchValue}>
			<input type="radio" on:click={() => (switchValue = true)} />{val1}
		</label>
		<label class="btn btn-secondary" class:active={!switchValue}>
			<input type="radio" on:click={() => (switchValue = false)} />{val2}
		</label>
	</div>
</div>

<style>
	.switch-btn {
		position: fixed;
		bottom: 15%;
		left: 50%;
		transform: translateX(-90px);
		z-index: 15;
		font-size: x-large;
		opacity: 0.85;
	}
</style>
