<!-- @format -->
<script>
	import { transmitter, procedure, options } from 'base_stores'
	import { onMount } from 'svelte'
	import { fetchGet } from 'utils/fetch'
	import { showModal } from '~/svelte/components/modal.svelte'
	import { currencyFormat } from 'utils/formats'
	import { isPresent } from 'utils/tools'
	import TableRow from './table_row.svelte'
	import ReceivableModal from './_modal.svelte'

	//export let mounted = true
	export let tableTitles = [
		'№ п/п',
		'Наименование дебитора',
		'Сумма задолженности (руб.)',
		'Доля в общей задолженности',
		'Средства, полученные от взыскания задолженности (руб.)',
		'Содержание и основание задолженности',
		'Меры, принятые по взысканию',
	];

	export let columns = {
		index: true,
		counterparty_name: true,
		receivable_name: true,
		debt_amount: true,
		debt_amount_percentage: true,
		paid_sum: true,
		debt_justification: true,
		note: true,
		total: true,
	};

	let receivables = []
	let noCounterpartySelected = true
	let forReceivablesPage = location.pathname === '/receivables'
	let mounted = false

	$: debitOption = $options.possessionKind && $options.possessionKind.find(kind => kind.code === 'debit')
	$: subkindOptions = debitOption && $options.possessionKind.filter(kind => String(kind.parent_id) === String(debitOption.id)) 
	//$: receivables = $transmitter && $transmitter.receivables || receivables
	$: totalDebtAmount = Math.round(
		receivables?.reduce((sum, receivable) => { return sum + parseFloat(receivable.debt_amount || 0) }, 0) * 100
	) / 100
	$: totalFundsFromDebtCollection = Math.round(
		receivables?.reduce((sum, receivable) => { return sum + parseFloat(receivable.paid_sum || 0) }, 0) * 100
	) / 100

	$: groupedReceivables = () => {
		let result = {};
		let indexOffset = 0;
		let currentReceivables = receivables;

		if (isPresent(subkindOptions)) {
			subkindOptions.forEach(option => {
				currentReceivables = receivables.filter(receivable => String(receivable.kind_id) === String(option.id));
				result[option.id] = { indexOffset: indexOffset, receivables: currentReceivables };
				indexOffset = indexOffset + currentReceivables.length;
			});
		}

		return result;
	};

	const openReceivableModalForm = async id => {
		$transmitter.receivableId = id;
		showModal('form-receivable-modal');
	};

	const getReceivables = () => {
		fetchGet('/api/private/receivables')
			.then(response => {
				$transmitter = { ...$transmitter, receivables: response.receivables }
				receivables = $transmitter.receivables
			})
	}

	onMount(() => {
		const procedureId = $procedure && $procedure.id;

		if (!procedureId) {
			return;
		}
	});
</script>

<table class="table table-hover table-mobile m-0 border-collapse_separate">
	<thead class="thead-light">
		<tr>
			{#each tableTitles as title}
				<th class="text-center p-w-xs">{title}</th>
			{/each}
		</tr>
	</thead>
	<tbody class="text-center">
		{#if isPresent(receivables)}
			{#if isPresent(subkindOptions)}
				{#each subkindOptions as subkind}
					<tr class="text-left subkind"><td colspan={forReceivablesPage ? 7 : 5}>{subkind.text}</td></tr>
					{#if groupedReceivables()[subkind.id].receivables.length}
						{#each groupedReceivables()[subkind.id].receivables as receivable, index}
							{#if forReceivablesPage}
								<TableRow {receivable} {columns} {openReceivableModalForm} index={index + groupedReceivables()[subkind.id].indexOffset} {totalDebtAmount} />
							{:else}
								<TableRow {receivable} {columns} {openReceivableModalForm} />
							{/if}
						{/each}
					{:else}
						<tr class="text-secondary"><td colspan={forReceivablesPage ? 7 : 5}>Нет данных</td></tr>
					{/if}
				{/each}
			{:else}
				{#each receivables as receivable, index}
					{#if forReceivablesPage}
						<TableRow {receivable} {columns} {openReceivableModalForm} {index} {totalDebtAmount} />
					{:else}
						<TableRow {receivable} {columns} {openReceivableModalForm} />
					{/if}
				{/each}
			{/if}
			{#if forReceivablesPage && columns.total}
				<tr class="font-weight-bold subkind">
					<td>Итого:</td>
					<td></td>
					<td class="text-right">{currencyFormat(totalDebtAmount)}</td>
					<td class="text-right">100%</td>
					<td class="text-right">{currencyFormat(totalFundsFromDebtCollection)}</td>
					<td></td>
					<td></td>
				</tr>
			{/if}
		{:else if mounted}
			<tr class="text-secondary"><td colspan={forReceivablesPage ? 7 : 5}>Нет данных</td></tr>
		{:else}
			<tr><td class="text-secondary" colspan={forReceivablesPage ? 7 : 5}><i class="fa fa-spinner fa-pulse fa-icon-large"></i></td></tr>
			{#if forReceivablesPage && columns.total}
				<tr class="font-weight-bold subkind">
					<td>Итого:</td>
					{#each Array(6) as _}
						<td></td>
					{/each}
				</tr>
			{/if}
		{/if}
	</tbody>
</table>
<ReceivableModal
	bind:noCounterpartySelected
	on:create={getReceivables}
	on:update={getReceivables}
	on:delete={getReceivables}
/>

<style>
	.subkind {
		background-color: #efefef;
	}
</style>
