<!-- @format -->
<script>
	import { percentFormat, currencyFormat } from 'utils/formats';

	export let title;
	export let sums;
</script>

<tr class="sub_title">
	<td colspan="4">{title}</td>
	<td class="text-right">{currencyFormat(sums.amount)}</td>
	<td class="text-right">{currencyFormat(sums.payment)}</td>
	<td class="text-right">{percentFormat(sums.paymentPercent)}</td>
	<td class="text-right">{currencyFormat((sums.amount || 0) - (sums.payment || 0))}</td>
	<td class="actions"></td>
</tr>

<style>
	.sub_title {
		font-weight: bold;
		color: #495057;
		background-color: rgba(0, 0, 0, 0.05) !important;
	}

	.sub_title > td {
		border-top: 1px solid #cecece;
		border-bottom: 1px solid #cecece;
	}
</style>
