<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { currentUserIsAdmin } from 'base_stores';
	import DeleteIcon from '~/svelte/components/dashboard/delete_icon.svelte';
	import IconDownload from '~/svelte/_shared/icon-download.svelte';
	import { isBlank } from 'utils/tools';

	const dispatch = createEventDispatcher();

	export let dctMetaRecord;
	export let switchValue = true;
	export let deleteIconDisabled = false;

	$: disabled = !dctMetaRecord.can_be_destroyed || deleteIconDisabled;
</script>

<div class="flex-slot">
	<ul class="nav navbar-top-links tools-bar flex-shrink-slot flex-nowrap" class:hidden-mobile={!switchValue}></ul>
	<ul class="nav navbar-top-links tools-bar flex-grow-slot flex-nowrap" class:hidden-mobile={switchValue}>
		{#if dctMetaRecord.id}
			<li class="m-l-sm m-r-md">
				<DeleteIcon modalId="delete-template-modal" bind:disabled />
			</li>
		{/if}
		{#if $currentUserIsAdmin}
			<li class="raf__with-tooltip">
				<button class="btn btn-xs btn-danger" disabled={isBlank(dctMetaRecord.template)} on:click={() => dispatch('downloadTemplate')}>
					<IconDownload />
				</button>
				<span class="raf__tooltip raf__tooltip_info"> Выгрузить шаблон. </span>
			</li>
		{/if}
	</ul>
</div>

<style lang="scss">
	@media (max-width: 576px) {
		.raf__tooltip {
			width: 200px !important;
		}
	}
</style>
