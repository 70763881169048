<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import IMask from 'imask';
	import InputErrorsString from './input_errors_string.svelte';
	import Hint from './hint.svelte';
	import InfoIcon from '../dashboard/info_icon.svelte';
	import { isPresent } from 'utils/tools';

	export let attribute;
	export let model;
	export let label;
	export let value;
	export let maskOptions;
	export let placeholder;
	export let required = false;
	export let disabled = false;
	export let errors;
	export let file;
	export let hintData;
	export let info = {};
	export let type = 'text';
	export let accept = '';
	export let labelCss = 'col-sm-4 col-lg-3 col-xl-4 col-form-label';
	export let divCss = 'col-sm-8 col-lg-9 col-xl-8';

	let inputField;
	let mask;
	let maxLength;

	const inputId = `input-${model}-${attribute}`; //-${(+new Date()).toString(16)}`

	$: isSuccess = value && !errors;

	function handleChange(event) {
		if (type === 'file') {
			file = event.target.files[0];
		}
	}

	const updateMaskValue = () => {
		if (mask) {
			mask.updateValue(mask.value);
			if (value !== mask.value) {
				value = mask.value;
			}
		}
	};

	const handleInputType = e => (e.target.type = type);

	onMount(() => {
		inputField.type = type;
		mask = maskOptions ? IMask(inputField, maskOptions) : null;
		mask && mask.on('complete', () => updateMaskValue());
		maxLength = maskOptions && maskOptions.mask.length;
	});
</script>

<div class="form-group row" class:has-error={errors} class:has-success={isSuccess}>
	<label for={inputId} class={labelCss} class:text-disabled={disabled}>
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class={divCss}>
		<input
			id={inputId}
			class:required-border={required && !value}
			class:file-input={type === 'file'}
			class="form-control"
			bind:value
			bind:this={inputField}
			on:input={handleInputType}
			on:change={handleChange}
			{disabled}
			{accept}
			{placeholder}
			maxlength={maxLength}
			autocomplete="disable"
		/>
		{#if errors}
			<InputErrorsString {errors} />
		{:else if hintData}
			<Hint {...hintData} />
		{/if}
		{#if isPresent(info)}
			<div class="info-wrapper">
				<InfoIcon tip={info.tip} left={true} />
			</div>
		{/if}
	</div>
</div>

<style>
	::file-selector-button {
		display: none;
	}

	.file-input:hover {
		cursor: pointer;
		background-color: #f3f4f6 !important;
	}

	.info-wrapper {
		position: absolute;
		top: 0.5rem;
		right: -0.5rem;
	}
</style>
