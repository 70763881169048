<script>
	import { createEventDispatcher } from 'svelte'
  import DestroyIcon from '../destroy_icon.svelte'
	import ErrorMessage from '~/svelte/components/message.svelte'
  import IconInfo from '../icon-info.svelte'
  import { fetchPost, fetchPut } from 'utils/fetch_helpers'
  import { addToFormData } from '~/svelte/components/ui/form/svelte-forms-lib'
  import { isPresent, isBlank } from 'utils/tools'

  export let loading = false
  export let disabled = false
  export let scansSet = {}

  const maxFileSizeMb = 50 //Mb
  const maxSize = maxFileSizeMb * 1024 * 1024
  const maxNameSize = 255

  let blockListWidth
  let files
  let errors
  let fileInputField
  let showInfo = false

  $: scans = scansSet?.scans?.filter(scan => !scan.deleted)

  const dispatch = createEventDispatcher()

  const getScansSetId = async () => {
    try {
      const result = await fetchPost('/api/private/scans_sets', { proprietor_type: scansSet.proprietor_type, proprietor_id: scansSet?.proprietor_id })
      return result.scans_set.id
    } catch(e) {
      loading = false
      errors = e
      throw e
    }
  }

  $: uploadFiles(files)

  const uploadFiles = async (files) => {
    if (isBlank(files)) { return }

    if (Object.values(files).some(f => !['image/png', 'image/jpeg', 'application/pdf'].includes(f.type))) {
      errors = 'Допустимые форматы файлов только: .pdf, .jpg, .jpeg, .png.'
      throw new Error(errors)
    }

    if (Object.values(files).some(f => f.size > maxSize)) {
      errors = `Превышен допустимый размер файла в ${maxFileSizeMb}Мб.`
      throw new Error(errors)
    }

    if (Object.values(files).some(f => (new Blob([f.name])).size > maxNameSize)) {
      errors = `Файл имеет слишком длинное имя.`
      throw new Error(errors)
    }

    errors = null
    loading = true
    const formData = new FormData()
    addToFormData(formData, 'scans_set', { scans: files })

    if (isBlank(scansSet.id)) {
      try {
        scansSet.id = await getScansSetId()
        if (isBlank(scansSet.id)) { throw new Error('ScansSet.ID not found.') }
      } catch(e) {
        errors = e
        throw new Error(errors)
      }
    }

    try {
      const result = await fetchPut(`/api/private/scans_sets/${scansSet.id}/add_scans`, formData)
      const ids = scansSet.scans.map(scan => scan.id)
      scansSet.scans = [...scansSet.scans, ...result.scans_set.scans.filter(scan => !ids.includes(scan.id)).map(scan => ({ ...scan, new: true }))]
      dispatch('changed', scansSet)
    } catch(e) {
      errors = e
      throw new Error(errors)
    } finally {
      loading = false
    }
  }

  const removeScan = async (scanId) => {
    scansSet.scans = scansSet.scans.map(scan => scan.id == scanId ? { ...scan, deleted: true } : scan)
    dispatch('changed', scansSet)
  }
</script>

<div class='col-sm-12 p-0'>
  <ul class='nav'>
    <li>
      <button
        class='btn btn-xs btn-warning m-b-xs m-r-sm'
        class:disabled={disabled || loading}
        disabled={disabled || loading}
        on:click={() => fileInputField.click()}
      >
        + Сканы
        {#if loading}
          <i class='fa fa-spinner fa-pulse fa-lg m-l-xs'/>
        {/if}
      </button>
    </li>
    <li class='mr-4'>
      <div
        class='my-popover m-r-sm'
        class:disabled={disabled || loading}
        role='button' tabindex=0
        disabled={disabled || loading}
        on:mouseover={() => !(disabled || loading) && (showInfo = true)}
        on:mouseout={()  => !(disabled || loading) && (showInfo = false)}
        on:focus={()  => !(disabled || loading) && (showInfo = true)}
        on:blur={()  => !(disabled || loading) && (showInfo = false)}
      >
        <IconInfo/>
        {#if showInfo}
          <div class='raf__tooltip show fade'>{`Допустимы файлы формата .pdf, .jpg, .jpeg или .png размером не более ${maxFileSizeMb}Мб.`}</div>
        {/if}
      </div>
    </li>
  </ul>
  {#if isPresent(scansSet?.proprietor_id)}
    <input bind:this={fileInputField} bind:files accept='image/png, image/jpeg, application/pdf' multiple='true' class='file-input' hidden={true} type='file'/>
  {/if}
  {#if errors}
    <ErrorMessage message={ errors } on:click={() => errors = null}/>
  {/if}
  <div class='p-1'>
    {#if isPresent(scans)}
      <ol class='block-list' bind:offsetWidth={blockListWidth}>
        {#each scans as scan}
          <li>
            <a href={scan.url} target='_blank' class='float-left simulated-link'
              style={`white-space: nowrap; overflow: hidden; width: ${blockListWidth - 32}px; display: inline-block; text-overflow: ellipsis;`}>
              {scan.name}
            </a>
            <span
              class='cursor_pointer float-right'
              class:disabled={isBlank(scan) || loading}
              style='opacity: 0.7;'
              title='удалить'
              role='button' tabindex=0
              on:click={() => { removeScan(scan.id); }}
              on:keypress|stopPropagation
            >
              <DestroyIcon/>
            </span>
          </li>
        {/each}
      </ol>
    {:else}
      <div style='color: #a8a8a8;'>Нет документов</div>
    {/if}
  </div>
</div>

<style lang='scss'>
  /*.nav > li > a {
    padding: 0 !important;
    min-height: 0 !important;
  }*/
  .my-popover {
    margin-top: 2px;
    position: relative;
    .show {
      position: absolute;
      z-index: 10010;
      background-color: #fff;
      border: solid 1px;
      border-radius: 0.3rem;
      box-shadow: 0 0 10px;
      display: block;
      padding: .7rem 1rem;
      top: -65px;
      width: 300px;
    }
  }
</style>
