<!-- @format -->
<script>
	import Input from '~/svelte/components/ui/input.svelte';
	import { isPresent } from 'utils/tools';
	import { fetchPost } from 'utils/fetch_helpers';
	import { transmitter } from 'base_stores';
	export let requiredFieldsFilled;

	let file;
	let value;
	let created = 0;
	let updated = 0;
	let incorrectRows = [];
	let workerFile = {
		required: true,
		attribute: 'worker_file',
		label: 'Выбрать файл',
		type: 'file',
		accept: '.xlsx, .xls',
	};

	$: requiredFieldsFilled = isPresent(value);

	export const handleSubmit = () => {
		if (requiredFieldsFilled) {
			const body = new FormData();
			body.append('workers_file', file);
			fetchPost('/api/private/workers/upload_list', body).then(response => {
				created = response.created || 0;
				updated = response.updated || 0;
				incorrectRows = response.incorrect_rows || 0;
				$transmitter.counterparties = response.workers || $transmitter.counterparties;
				value = file = null;
			});
		}
	};
</script>

<div>
	<Input {...workerFile} bind:value bind:file />
	<div class="form-group row">
		<label class="col-sm-4 col-lg-3 col-xl-4 col-form-label text-field" for="">
			<p>Требования к файлу:</p>
			<ul>
				<li>Допустимые форматы: .xlsx, .xls</li>
				<li>Создан на основании нашего шаблона</li>
				<li>Шапка (первая строка) из шаблона сохраняется</li>
				<li>Формат данных соответствует формату ячеек из примера</li>
				<li>Обязательные поля: Имя, Фамилия</li>
				<li>Если работник с таким ФИО существует, данные обновятся</li>
			</ul>
		</label>
	</div>
	<div class="form-group row">
		<label class="col-sm-4 col-lg-3 col-xl-4 col-form-label text-field" for="">
			<p>Coздано: {created}</p>
			<p>Обновлено: {updated}</p>
			<p>Некорректные данные{isPresent(incorrectRows) ? ' (номер строки. имя):' : ': 0'}</p>
			<ul class="no-bullets">
				{#each incorrectRows as row}
					<li>{row}</li>
				{/each}
			</ul>
		</label>
	</div>
</div>

<style>
	.text-field {
		flex: none !important;
		max-width: 100% !important;
	}
	.no-bullets {
		list-style-type: none; /* Убираем маркеры точки из элементов списка */
	}
	ul {
		padding-left: 30px;
	}
	p {
		margin-bottom: 0;
	}
</style>
