<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { isPresent, isArray, randomString } from '~/js/utils/tools.js';
	import Modal, { showModal } from '~/svelte/components/modal.svelte';
	import { transmitter } from '~/js/global_stores/base.js';
	import Form from './_form.svelte'
	import CounterpartyModal from '~/svelte/counterparties/_modal.svelte';
	import {fetchGet} from '~/js/utils/fetch'
	import { clearScans } from '~/svelte/_shared/scans_sets/scans_set.js'

	const collator = new Intl.Collator();

	const dispatch = createEventDispatcher();

	export let index = 0;
	export let storage = [];

	export const newItem = (options = {}) => {
		formOptions = { item: {}, method: 'new', ...options };
		showModal(modalId);
	};
	export const editItem = (options = {}) => {
		formOptions = { ...options, method: 'edit' };
		showModal(modalId);
	};
	export const showItem = (options = {}) => {
		formOptions = { ...options, method: 'show', readonly: true };
		showModal(modalId);
	};

	const modalId = randomString();
	const counterpartyModalId = randomString();

	let formOptions = {};
	let form;
	let formCompleted = false;
	let isNewInstance
	let scansSetChanged = false

	const formSubmit = async () => {
		const formData = form.getItem()
		const result = await form.submitForm()

		if (isPresent(result)) {
			if (isArray(storage)) {
				const arr = [...storage].filter(row => row.id != result.item.id).concat([result.item]);
				storage = [...arr];
			}

			const scansSet = await clearScans(formData.scans_set, 'deleted')
			storage = storage?.map(s => s.id == result.item.id ? { ...s, scans_set: scansSet } : s)

			dispatch(formOptions.method == 'edit' ? 'update' : 'create', result);
			return result;
		}
	}

	const formDelete = () => form.deleteItem().then(id => {
		if( isArray(storage) ) {
			storage = storage.filter(row => row.id != id);
		}
		dispatch('delete', id);
		return id;
	})

	const formCancel = () => {
		const formData = form.getItem()
		if (isNewInstance) {
			if (formData?.id) { formDelete() }
		} else {
			clearScans(formData.scans_set, 'new')
		}
	}

	const counterpartyNew = () => showModal(counterpartyModalId);
	const counterpartyCreated = async ({ detail: id }) => {
		await fetchGet('/api/private/counterparties', { filters: { roles: { only: ['meeting_participant', 'worker'] } } }).then(response => {
			$transmitter = { ...$transmitter, counterparties: response.counterparties.sort((a, b) => collator.compare(a.name, b.name)) };
		});

		const el = $transmitter.counterparties.find(e => e.id == id);
		if (isPresent(el)) {
			form.setData({
				counterparties: [
					...form.getData().counterparties,
					{
						value: id,
						label: el.name,
						voting_sum: 0.0,
						unsecured_voting_sum: 0.0,
					},
				],
			});
			form.setItem({
				counterparty_id: id,
				voting_sum: 0.0,
				unsecured_voting_sum: 0.0,
			});
		}
	};
</script>

<Modal
	modalId={modalId}
	submitButtonDisabled={(formOptions.readonly || formOptions.method == 'show' || !formCompleted) && !scansSetChanged}
	submitButtonAction={formSubmit}
	deleteIconDisabled={formOptions.readonly || formOptions.method == 'new'}
	deleteButtonAction={formDelete}
	cancelButtonAction={formCancel}
	deleteConfirmMessage='Вы действительно хотите удалить участника?'
	showCopyAndSaveButton={ false }
	showError={ false }
	form={true}
	{index}
	size='large'
	on:hide={() => isNewInstance = false}
>
	<h2 slot='header'>Карточка участника собрания</h2>
	<div slot='body'>
		<Form
			bind:this={form}
			bind:isCompleted={formCompleted}
			bind:isNewInstance
			bind:scansSetChanged
			{formOptions}
			on:newCounterparty={counterpartyNew}
		/>
	</div>
</Modal>

<CounterpartyModal modalId={counterpartyModalId} index={index + 1} fixedRoles={['worker']} personage="работника" on:create={counterpartyCreated} />
