<!-- @format -->
<script>
	import { payments, paymentSubscriptions } from 'base_stores';
	import { currentPaymentSubscription, hiddenPaymentModal } from '~/svelte/components/payment/stores';
	import { onMount } from 'svelte';
	import { fetchGet, fetchPost } from 'utils/fetch';
	import Modal, { hideModal, showModal } from '~/svelte/components/modal.svelte';
	import moment from 'moment';
	import Payment from './payment_row.svelte';
	import PaymentSubscription from './payment_subscription_row.svelte';

	moment.locale('ru');

	const paymentsTableTitles = ['Дата', 'Сумма, руб.', 'Процедура', 'Документ'];
	const paymentSubscriptionsTableTitles = ['Процедура', 'Статус подписки', 'Оплачена до', 'Изменение статуса подписки'];

	let confirmedPaymentSubscription = false;
	let activeModalId;
	let collapsedPayments = false;
	let collapsedSubscriptions = false;

	const goToProcedure = procedureId => {
		location.replace(`/procedures/${procedureId}`);
	};

	const showConfirmation = action => {
		$hiddenPaymentModal = true;
		activeModalId = `confirm-${action}-payment-subscription-modal`;
		showModal(activeModalId);
	};
	const showActivationConfirmation = subscription => {
		$currentPaymentSubscription = subscription;
		if (
			!$currentPaymentSubscription.next_payment_date ||
			moment($currentPaymentSubscription.next_payment_date).isSameOrBefore(moment()) ||
			!$currentPaymentSubscription.rebill_id
		) {
			$hiddenPaymentModal = false;
		} else {
			showConfirmation('activation');
		}
	};
	const showDeactivationConfirmation = subscription => {
		$currentPaymentSubscription = subscription;
		showConfirmation('deactivation');
	};
	const toggleSubscriptionState = () => {
		const id = $currentPaymentSubscription.id;
		const state = !$currentPaymentSubscription.active;
		fetchPost('/api/private/payment_subscriptions/change_payment_subscription_state', { payment_subscription_id: id, state }).then(response => {
			$paymentSubscriptions = $paymentSubscriptions.map(s => (s.id == response.payment_subscription.id ? response.payment_subscription : s));
			confirmedPaymentSubscription = false;
			hideModal(activeModalId);
		});
	};

	const nextPaymentDateStr = subscription =>
		($currentPaymentSubscription.rebill_id ? moment(subscription.next_payment_date) : moment(subscription.next_payment_date).add(1, 'months')).format('LLL');

	onMount(() => {
		fetchGet('/api/private/payments').then(response => {
			$payments = response.payments;
			$paymentSubscriptions = response.payment_subscriptions;
		});
	});
</script>

<div
	title={collapsedPayments ? 'скрыть платежи' : 'показать платежи'}
	role="button"
	tabindex="0"
	on:click={() => (collapsedPayments = !collapsedPayments)}
	on:keypress|stopPropagation
>
	<h3 class="ml-1 mt-3">
		2. История платежей
		{#if collapsedPayments}
			<iconify-icon icon="fa-solid:chevron-up" class="m-r-sm hoverable" />
		{:else}
			<iconify-icon icon="fa-solid:chevron-down" class="m-r-sm hoverable" />
		{/if}
	</h3>
</div>
<div class="collapse" class:show={collapsedPayments} id={`collapse-payments`}>
	<div class="m-b-md overlay p-1">
		<table class="table table-hover m-0 border-collapse_separate">
			<thead class="thead-light">
				<tr>
					{#each paymentsTableTitles as title}
						<th class="text-center p-w-xs">{title}</th>
					{/each}
				</tr>
			</thead>
			<tbody class="text-center">
				{#if $payments.length}
					{#each $payments as payment}
						<Payment {payment} {goToProcedure} />
					{/each}
				{:else}
					<tr><td class="text-secondary" colspan={paymentsTableTitles.length}>Нет данных</td></tr>
				{/if}
			</tbody>
		</table>
	</div>
</div>
<div
	title={collapsedSubscriptions ? 'скрыть подписки' : 'показать подписки'}
	role="button"
	tabindex="0"
	on:click={() => (collapsedSubscriptions = !collapsedSubscriptions)}
	on:keypress|stopPropagation
>
	<h3 class="ml-1 mt-3">
		3. Подписки
		{#if collapsedSubscriptions}
			<iconify-icon icon="fa-solid:chevron-up" class="m-r-sm hoverable" />
		{:else}
			<iconify-icon icon="fa-solid:chevron-down" class="m-r-sm hoverable" />
		{/if}
	</h3>
</div>
<div class="collapse" class:show={collapsedSubscriptions} id={`collapse-subscriptions`}>
	<div class="m-b-lg overlay p-1 footer-margin">
		<table class="table table-hover table-mobile m-0 border-collapse_separate">
			<thead class="thead-light">
				<tr>
					{#each paymentSubscriptionsTableTitles as title}
						<th class="text-center p-w-xs">{title}</th>
					{/each}
				</tr>
			</thead>
			<tbody class="text-center">
				{#if $paymentSubscriptions.length}
					{#each $paymentSubscriptions as paymentSubscription}
						<PaymentSubscription {paymentSubscription} {showDeactivationConfirmation} {showActivationConfirmation} {goToProcedure} />
					{/each}
				{:else}
					<tr><td class="text-secondary" colspan={paymentsTableTitles.length}>Нет данных</td></tr>
				{/if}
			</tbody>
		</table>
	</div>
</div>
<Modal
	modalId="confirm-activation-payment-subscription-modal"
	submitButtonText="Активировать"
	submitButtonCss="btn-primary m-r-sm"
	submitButtonAction={toggleSubscriptionState}
	submitButtonDisabled={!confirmedPaymentSubscription}
	size="medium"
>
	<h2 slot="header">Активировать автоплатежи процедуры<br />{$currentPaymentSubscription.procedure_name} ?</h2>
	<div slot="body">
		<h4>
			<p>Следующий платёж будет совершён {nextPaymentDateStr($currentPaymentSubscription)}</p>
			<p>(регулярный платёж {$currentPaymentSubscription.total_amount / 100} рублей в месяц).</p>
			<p>
				<input
					type="checkbox"
					id="confirm-activation-payment-subscription-checkbox"
					bind:checked={confirmedPaymentSubscription}
					class="align-self-center m-r-xs m-l-xs"
				/>
				<label class="label-text" for="confirm-activation-payment-subscription-checkbox">
					Согласен с автосписанием {$currentPaymentSubscription.total_amount / 100} руб./мес. и принимаю
					<a href="https://rafinad.ai/reccurent" target="_blank">Оферту.</a>
				</label>
			</p>
		</h4>
	</div>
</Modal>
<Modal
	modalId="confirm-deactivation-payment-subscription-modal"
	submitButtonText="Прекратить"
	submitButtonCss="btn-danger m-r-sm"
	submitButtonAction={toggleSubscriptionState}
	size="medium"
>
	<h2 slot="header">Прекратить автоплатежи процедуры<br />{$currentPaymentSubscription.procedure_name} ?</h2>
	<div slot="body">
		<h4>
			<p>Процедура оплачена до {nextPaymentDateStr($currentPaymentSubscription)}.</p>
			<p>После {nextPaymentDateStr($currentPaymentSubscription)} доступ к процедуре будет ограничен.</p>
		</h4>
	</div>
</Modal>

<style>
	iconify-icon[icon='fa-solid:chevron-up'] {
		vertical-align: top;
	}

	iconify-icon[icon='fa-solid:chevron-down'] {
		vertical-align: bottom;
	}
</style>
