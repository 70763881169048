<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { fetchGet, fetchPatch } from '~/js/utils/fetch_helpers.js';
	import { isPresent, randomString, uniqueArray } from '~/js/utils/tools.js';
	import Modal, { showModal } from '~/svelte/components/modal.svelte';
	import ErrorMessage from '~/svelte/components/message.svelte';

	const dispatch = createEventDispatcher();

	export let index = 0;
	export let storage = [];
	export const newItem = args => {
		id = args.meeting && args.meeting.id;
		selected = [];

		fetchGet('/api/private/meetings/' + id + '/get_notification_kinds').then(result => {
			rows = result.rows;
			allIds = rows.map(e => e.id);
			kinds = rows.filter(e => e.type == 'meta');
			participants = rows.filter(e => e.type == 'debtor_participant');
			creditors = rows.filter(e => e.type == 'creditor');

			const preselected_meta = ['bankrupt', 'sro', 'court', 'rosreestr', 'fedresource', 'creditor', 'debtor_participant'];
			selected = rows.filter(e => preselected_meta.includes(e.kind)).map(e => e.id);
		});
		showModal(modalId);
	};

	let id;
	let rows = [];
	let kinds = [];
	let errors = [];
	let allIds = [];
	let participants = [];
	let creditors = [];
	let selected = [];
	let selectedAll = false;
	let selectedAllParticipants = false;
	let selectedAllCreditors = false;

	const modalId = randomString();

	const toggleAll = e => (selected = e.target.checked ? [...allIds] : []);
	const toggleAllParticipants = e =>
		(selected = e.target.checked ? uniqueArray([...selected, ...participants.map(e => e.id)]) : selected.filter(e => !e.startsWith('debtor_participant')));
	const toggleAllCreditors = e =>
		(selected = e.target.checked ? uniqueArray([...selected, ...creditors.map(e => e.id)]) : selected.filter(e => !e.startsWith('creditor')));

	const formSubmit = () => {
		if (isPresent(selected)) {
			const arr = rows.filter(e => selected.includes(e.id));
			const substitutions = {};
			const params = {
				meeting: {
					notifications_attributes: arr.map(e => ({ meta_record_id: e.id, substitutions })),
				},
			};
			// узнаем максимальный id уведомления, чтобы узнать первый id новых уведомлений
			//const maxId = storage.length ? Math.max.apply(null, storage.map(e => e.id)) + 1 : 0

			return fetchPatch('/api/private/meetings/' + id, params)
				.then(result => {
					storage = [...result.notifications];
					//const closestId = storage.map(e => e.id).reduce((prev, curr) => Math.abs(curr - maxId) < Math.abs(prev - maxId) ? curr : prev, 0)
					//dispatch('create', { id: closestId })
					dispatch('create', { storage, id: storage[storage.length - 1].id });
				})
				.catch(error => {
					errors = error;
					throw error;
				});
		}
	};

	$: selectedAll = selected.length == allIds.length;
	$: selectedAllParticipants =
		selected.filter(e => e.startsWith('debtor_participant')).length == rows.filter(e => e.id.startsWith('debtor_participant')).length;
	$: selectedAllCreditors = selected.filter(e => e.startsWith('creditor')).length == rows.filter(e => e.id.startsWith('creditor')).length;
</script>

<Modal
	{modalId}
	deleteIcon={false}
	submitButtonText="Создать"
	showCopyAndSaveButton={false}
	submitButtonDisabled={selected.length == 0}
	submitButtonAction={formSubmit}
	showError={false}
	form={true}
	{index}
	size="medium"
>
	<h2 slot="header">Создать уведомление</h2>
	<div slot="body">
		<div class="columns">
			<ul class="m-b-0 mr-auto">
				<li>
					<label>
						<input class="cursor_pointer" type="checkbox" checked={selectedAll} on:click={toggleAll} />
						<strong>Выбрать все</strong>
					</label>
				</li>
			</ul>
		</div>
		<div class="columns">
			<ul class="mr-auto">
				{#each kinds as row}
					<li>
						<label>
							<input class="cursor_pointer" type="checkbox" bind:group={selected} value={row.id} />
							{row.name}
						</label>
					</li>
				{/each}
			</ul>
			<ul class="ml-auto">
				{#if creditors.length}
					<li>
						<label>
							<input class="cursor_pointer" type="checkbox" checked={selectedAllCreditors} on:click={toggleAllCreditors} />
							<strong>Кредиторы</strong>
						</label>
					</li>
					{#each creditors as row}
						<li style="padding-left: 18px;">
							<label>
								<input class="cursor_pointer" type="checkbox" bind:group={selected} value={row.id} />
								{row.name}
							</label>
						</li>
					{/each}
				{/if}
				{#if participants.length}
					<li>
						<label>
							<input class="cursor_pointer" type="checkbox" checked={selectedAllParticipants} on:click={toggleAllParticipants} />
							<strong>Участники должника</strong>
						</label>
					</li>
					{#each participants as row}
						<li style="padding-left: 18px;">
							<label>
								<input class="cursor_pointer" type="checkbox" bind:group={selected} value={row.id} />
								{row.name}
							</label>
						</li>
					{/each}
				{/if}
			</ul>
		</div>
		<ErrorMessage message={errors} on:click={() => (errors = [])} />
	</div>
</Modal>

<style lang="scss">
	.columns {
		display: flex;
		ul {
			list-style-type: none;
			padding-left: 0;
			min-width: 50%;

			input[type='checkbox'] {
				margin-right: 5px;
			}
		}
	}
</style>
