<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { fetchGet } from 'utils/fetch_helpers';
	import { isPresent, isBlank, toArray, toFloat, formatSum, formatDate, debounce } from 'utils/tools';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import Form from '~/svelte/components/ui/form/_form.svelte';
	import Field from '~/svelte/components/ui/form/_form_field.svelte';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import InputMoney from '~/svelte/components/ui/input_money.svelte';
	import RepaymentOrderPersonInfo from '~/svelte/_shared/repayment_order_person_info_block.svelte';
	import RepaymentOrderOrganizationInfo from '~/svelte/_shared/repayment_order_organization_info_block.svelte';
	import RepaymentOrderRTKInfo from '~/svelte/_shared/repayment_order_rtk_info_block.svelte';
	import IconInfo from '~/svelte/_shared/icon-info.svelte';
	import ScansSet from '~/svelte/_shared/scans_sets/_set_field.svelte'
	import { procedure } from 'base_stores'

	const dispatch = createEventDispatcher();

	export let isNewInstance
	export let formOptions
	export let isFormCompleted
	export const submitForm = () => formControl.submitForm();
	export const deleteItem = () => formControl.deleteItem();
	export const getItem = () => formControl.getItem();
	export const setItem = changes => formControl.setItem(changes);
	export const getData = () => data;
	export const setData = changes => { data = { ...data, ...changes } };
	export let disabled = false;

	let formControl;
	let form, errorsBase, handleChange, validateField, processErrors;
	let data = {};

	$: if (isPresent($form?.id) && !$form.bank_account_id) {
		$form.bank_account_id = 'cash';
	}

	const setInitialazedForm = instance => {
		form = instance.form;
		errorsBase = instance.errorsBase;
		handleChange = instance.handleChange;
		validateField = instance.validateField;
		processErrors = instance.processErrors;
	};

	const setDictionariesValues = values => {
		data = { ...values };
		data.bank_accounts = [{ value: -1, label: 'Добавить счёт', action: true }, { value: 'cash', label: 'Наличные', action: false }, ...data.bank_accounts];
		data.payees = [{ value: -1, label: 'Добавить получателя', action: true }, ...data.payees];
	};

	const setSubmitParams = () => {
		const payout_lines_attributes = $form.payout_lines.map(e => {
			return {
				id: e.id,
				payoutable_id: e.payoutable_id,
				payoutable_type: e.payoutable_type,
				sum: e.sum,
				_destroy: toFloat(e.sum) == 0,
			};
		});

		return { payout_lines_attributes, bank_account_id: $form.bank_account_id == 'cash' ? null : $form.bank_account_id };
	};

	const handleNewBankAccount = currentBankAccountId => dispatch('newBankAccount', currentBankAccountId);
	const handleNewPayee = () => dispatch('newPayee');

	let repaymentOrderInfoShow = false;
	let current_kind;
	let current_repayment_order;
	let current_counterparty_id;
	let current_behind_registry;
	let total_sum = 0;
	let isLoaded;
	let isCompleted = false;
	let focusedByPayoutLine = false;
	let payoutLinesSums = [];

	const resetCounterparty = () => {
		const id = 1 * $form.counterparty_id;
		if ($form.kind == 'running_costs') {
			if (!data.current_creditors.map(e => e.value).includes(id)) {
				$form.counterparty_id = '';
			}
		} else if ($form.kind == 'rtk') {
			if (!data.creditors.map(e => e.value).includes(id)) {
				$form.counterparty_id = '';
			}
		} else if ($form.kind == 'other') {
			if (!data.payees.map(e => e.value).includes(id)) {
				$form.counterparty_id = '';
			}
		}
	};

	const resetRepaymentOrder = () => {
		if ($form.kind == 'running_costs') {
			if (!data.repayment_orders.running_costs.map(e => e.value).includes($form.repayment_order)) {
				$form.repayment_order = '';
			}
		} else if ($form.kind == 'rtk') {
			if (!data.repayment_orders.rtk.map(e => e.value).includes($form.repayment_order)) {
				$form.repayment_order = '';
			}
		}
	};

	const refreshExpenses = () => {
		//if (isNewInstance && formKindRTK && isPresent($form?.payout_lines)) { return }
		if (!(formKindRunningCosts || formKindRTK)) { return }

		if (isLoaded) {
			if (isPresent($form.kind) && isPresent($form.counterparty_id) && isPresent($form.repayment_order)) {
				const params = {
					id: $form.id || '',
					kind: $form.kind,
					counterparty_id: $form.counterparty_id,
					repayment_order: $form.repayment_order,
					behind_registry: $form.behind_registry,
				};

				fetchGet('/api/private/payouts/payout_lines', params)
					.then(result => {
						$form.payout_lines = result.rows.filter(pl => toFloat(pl.sum) || toFloat(pl.unpaid_sum));
						payoutLinesSums = $form.payout_lines.map(pl => toFloat(pl.unpaid_sum));
						calcFormSum();
						/*if (formKindRunningCosts) {
							$form.payout_lines = result.rows.filter(pl => toFloat(pl.sum) || toFloat(pl.unpaid_sum));
							payoutLinesSums = $form.payout_lines.map(pl => toFloat(pl.unpaid_sum));
							calcFormSum();
						} else {
							$form.payout_lines = result.rows;
							$form.payout_lines = $form.payout_lines.map(pl => ({ ...pl, sum: pl.unpaid_sum }));
							calcFormSum();
						}*/
					})
					.catch(processErrors);
			} else {
				$form.payout_lines = [];
			}
		}
	};

	const calcFormSum = () => {
		if (toFloat($form.sum) > 0) {
			calcSums();
		} else {
			$form.sum = $form.payout_lines.reduce((sum, e) => sum + toFloat(e.unpaid_sum), 0.0) - $form.payout_lines.reduce((sum, e) => sum + toFloat(e.sum), 0.0);
		}
	};

	const calcSums = () => {
		//if (isNewInstance && isPresent(total_sum)) { return }

		if (!manualControlByPayoutLines) {
			if (isPresent(payoutLinesSums)) {
				$form.payout_lines.map((pl, i) => (pl.sum = payoutLinesSums[i]));
			} else {
				payoutLinesSums = $form.payout_lines.map(pl => toFloat(pl.unpaid_sum));
			}
			const payoutLinesSum = Math.round(toArray($form.payout_lines).reduce((sum, e) => sum + toFloat(e.sum), 0.0) * 100) / 100;
			total_sum = payoutLinesSum > toFloat($form.sum) ? toFloat($form.sum) : payoutLinesSum;
			validateField('sum');

			if (total_sum < payoutLinesSum) {
				let delta = total_sum - payoutLinesSum;

				for (let i = $form.payout_lines.length; i > 0; i--) {
					delta = toFloat($form.payout_lines[i - 1].sum) + delta;

					if (delta < 0) {
						$form.payout_lines[i - 1].sum = 0;
					} else {
						$form.payout_lines[i - 1].sum = delta;
						i = 0;
					}
				}
			}
		} else {
			total_sum = Math.round(toArray($form.payout_lines).reduce((sum, e) => sum + toFloat(e.sum), 0.0) * 100) / 100;
			validateField('sum');
		}
	};

	const isSumValid = value =>
		!manualControlByPayoutLines ? (isPresent($form?.payout_lines) && toFloat(value) > 0 ? toFloat(value) == total_sum : true) : toFloat(value) == total_sum;

	const bankAccountOptions = id => {
		const justSelectedBankAccount = data.bank_accounts.find(ba => ba.value == id);
		return data.bank_accounts.filter(
			ba => (ba.value != id && (justSelectedBankAccount ? ba.currency == justSelectedBankAccount.currency : true)) || ba.value == -1,
		);
	};

	$: isFormCompleted = (!(formKindRTK || formKindRunningCosts) || (isPresent($form) && isSumValid($form.sum, total_sum))) && isCompleted;

	$: formKindRunningCosts = isPresent($form) && $form.kind == 'running_costs';
	$: formKindRTK = isPresent($form) && $form.kind == 'rtk';
	$: manualControlByPayoutLines = isPresent($form) && isPresent($form.kind) && (!(formKindRunningCosts || formKindRTK) || manualControlByPayoutLines);
	$: if (formKindRunningCosts || formKindRTK) { manualControlByPayoutLines = false }

	$: if (isPresent($form) && $form.kind != current_kind) {
		current_kind = $form.kind;
		current_repayment_order = '';
		current_counterparty_id = '';
	}
	$: if (isPresent($form) && $form.repayment_order != current_repayment_order) current_repayment_order = $form.repayment_order;
	$: if (isPresent($form) && $form.counterparty_id != current_counterparty_id) current_counterparty_id = $form.counterparty_id;
	$: if (isPresent($form) && $form.behind_registry != current_behind_registry) current_behind_registry = $form.behind_registry;

	$: isPresent($form) && resetCounterparty(current_kind)
	$: isPresent($form) && resetRepaymentOrder(current_repayment_order)
	$: isPresent($form) && calcSums($form)
	$: refreshExpenses(current_kind, current_repayment_order, current_counterparty_id, current_behind_registry)

	$: filledRequiredFields = $form &&
		isPresent($form.date_at) &&
		isPresent($form.bank_account_id) && Number.isInteger(+$form.bank_account_id) &&
		isPresent($form.kind) &&
		isPresent($form.note) &&
		isPresent($form.num) &&
		($form.sum > 0) &&
		($form.kind == 'between_accounts' ? isPresent($form.target_bank_account_id) : isPresent($form.counterparty_id)) &&
		(['running_costs', 'rtk'].includes($form.kind) ? isPresent($form.repayment_order) : true)

	let creating = false
	$: if (isBlank($form?.id) && filledRequiredFields && (manualControlByPayoutLines || (manualControlByPayoutLines && isSumValid($form.sum, total_sum)))) {
		if (!creating) { debounce(secretlyСreate, 700) }
		creating = true
	}

	const secretlyСreate = async () => {
		try {
			const response = await submitForm()
			setItem(response?.item?.payout)
			creating = false
		} catch (errors) {
			console.error('errors: ' + JSON.stringify(errors))
			throw e;
		}
	}

	$: if (isPresent($form) && !isNewInstance) { isNewInstance = isBlank($form?.id) }
</script>

<Form
	bind:this={formControl}
	bind:isCompleted
	bind:isLoaded
	formOptions={{ ...formOptions, model: 'payout', controller: 'payouts' }}
	{setDictionariesValues}
	{setSubmitParams}
	{setInitialazedForm}
>
	<!-- Дата платежа -->
	<Datepicker
		required={true}
		model='bank_account'
		attribute='date_at'
		label='Дата платежа'
		placeholder='дд.мм.гггг'
		maskOptions={{ mask: Date }}
		{disabled}
		bind:value={$form.date_at}
	/>
	<!-- Сумма платежа (руб.) -->
	<Field name="sum" label="Сумма платежа (руб.)" short={true} required={true}>
		<InputMoney {disabled} name="sum" bind:value={$form.sum} onchange={calcSums} klass="form-control text-right required-border" />
	</Field>
	<!-- Счёт, где прошёл платёж -->
	<Field name="bank_account_id" label="Счёт, где прошел платёж" required={true}>
		<Select
			isDisabled={disabled}
			name="bank_account_id"
			items={data.bank_accounts && bankAccountOptions($form.target_bank_account_id)}
			value={$form.bank_account_id}
			onchange={handleChange}
			on:action={() => handleNewBankAccount('bank_account_id')}
			containerClasses="form-control"
			placeholder="Выбрать счёт"
		></Select>
	</Field>
	<!-- Вид платежа -->
	<Field name="kind" label="Вид платежа" required={true}>
		{#each toArray(data.kinds) as option}
			<label class="d-flex">
				<input {disabled} type="radio" bind:group={$form.kind} class="align-self-center m-r-xs" value={option.value} />
				{option.label}
			</label>
		{/each}
	</Field>

	{#if $form.kind == 'between_accounts'}
		<!-- Счёт, куда поступил платёж -->
		<Field name="target_bank_account_id" label="Счёт, куда поступил платёж" required={true}>
			<Select
				isDisabled={disabled}
				name="target_bank_account_id"
				items={data.bank_accounts && bankAccountOptions($form.bank_account_id)}
				value={$form.target_bank_account_id}
				onchange={handleChange}
				on:action={() => handleNewBankAccount('target_bank_account_id')}
				containerClasses="form-control"
				placeholder="Выбрать счёт"
			></Select>
		</Field>
	{/if}

	{#if $form.kind == 'running_costs'}
		<!-- Текущий кредитор (получатель) -->
		<Field name="counterparty_id" label="Текущий кредитор (получатель)" required={true}>
			<Select
				isDisabled={disabled}
				name="counterparty_id"
				items={data.current_creditors}
				value={$form.counterparty_id}
				onchange={handleChange}
				containerClasses="form-control"
				placeholder="Выбрать кредитора"
			></Select>
		</Field>
	{:else if $form.kind == 'rtk'}
		<!-- Кредитор (получатель) -->
		<Field name="counterparty_id" label="Кредитор (получатель)" required={true}>
			<Select
				isDisabled={disabled}
				name="counterparty_id"
				items={data.creditors}
				value={$form.counterparty_id}
				onchange={handleChange}
				containerClasses="form-control"
				placeholder="Выбрать кредитора"
			></Select>
		</Field>
	{:else if ['bankrupt_salary', 'other'].includes($form.kind)}
		<!-- Получатель платежа -->
		<Field name="counterparty_id" label="Получатель" required={true}>
			<Select
				isDisabled={disabled}
				name="counterparty_id"
				items={data.payees}
				value={$form.counterparty_id}
				onchange={handleChange}
				on:action={handleNewPayee}
				containerClasses="form-control"
				placeholder="Выбрать получателя"
			></Select>
		</Field>
	{/if}
	<!-- Очередность погашения -->
	{#if $form.kind == 'running_costs'}
		<Field name="repayment_order" label="Очередность погашения" required={true}>
			<Select
				isDisabled={disabled}
				name="repayment_order"
				items={data.repayment_orders?.running_costs}
				value={$form.repayment_order}
				onchange={handleChange}
				containerClasses="form-control"
				placeholder="Выбрать очередь"
			></Select>

			<div
				class="wrapper-info-icon"
				role="button"
				tabindex="0"
				on:mouseover={() => (repaymentOrderInfoShow = true)}
				on:mouseout={() => (repaymentOrderInfoShow = false)}
				on:focus={() => (repaymentOrderInfoShow = true)}
				on:blur={() => (repaymentOrderInfoShow = false)}
			>
				<IconInfo />
			</div>

			{#if repaymentOrderInfoShow}
				<div class="repayment_order__popover show fade">
					{#if $procedure.bankrupt_type == 'Organization'}
						<RepaymentOrderOrganizationInfo />
					{:else if $procedure.bankrupt_type == 'Person'}
						<RepaymentOrderPersonInfo />
					{/if}
				</div>
			{/if}
		</Field>
	{:else if $form.kind == 'rtk'}
		<Field name="repayment_order" label="Очередность погашения" required={true}>
			<Select
				isDisabled={disabled}
				name="repayment_order"
				items={data.repayment_orders.rtk}
				value={$form.repayment_order}
				onchange={handleChange}
				containerClasses="form-control"
				placeholder="Выбрать очередь"
			></Select>

			<div
				class="wrapper-info-icon"
				role="button"
				tabindex="0"
				on:mouseover={() => (repaymentOrderInfoShow = true)}
				on:mouseout={() => (repaymentOrderInfoShow = false)}
				on:focus={() => (repaymentOrderInfoShow = true)}
				on:blur={() => (repaymentOrderInfoShow = false)}
			>
				<IconInfo />
			</div>

			{#if repaymentOrderInfoShow}
				<div class="repayment_order__popover show fade">
					<RepaymentOrderRTKInfo />
				</div>
			{/if}
		</Field>
	{/if}
	<!-- Требования за реестром -->
	{#if $form.kind == 'rtk'}
		<Field name="behind_registry" label="Требования за реестром" fieldClasses="col-sm-8 col-lg-9 col-xl-8 d-flex align-items-center checkbox-left">
			<input
				{disabled}
				id="checkbox_behind_registry"
				name="behind_registry"
				type="checkbox"
				class="align-self-center m-r-sm m-l-xs"
				bind:checked={$form.behind_registry}
				on:change={handleChange}
			/>
			<label class="label-text" for="checkbox_behind_registry" style="margin-bottom: 0;">да, за реестром</label>
		</Field>
	{/if}
	<!-- Оплачиваемые расходы / Оплачиваемые требования -->
	<label for class="col-form-label">
		{#if $form.kind == 'running_costs'}
			Оплачиваемые расходы
			<span class="form-text text-muted">AI заполнил долгами по расходам данного кредитора и очередности</span>
		{:else if $form.kind == 'rtk'}
			Оплачиваемые требования
			<span class="form-text text-muted">AI заполнил долгами по требованиям данного кредитора и очередности</span>
		{/if}
	</label>
	{#if $form.kind == 'running_costs' || $form.kind == 'rtk'}
		<div class="m-b-lg overlay p-1">
			<table class="table table-stripped table-mobile table-hover m-0 border-collapse_separate loc-font">
				<thead class="thead-light">
					<tr>
						<th class="text-center p-w-xs">Дата</th>
						<th class="text-center p-w-xs">{$form.kind == 'running_costs' ? 'Вид расхода' : 'Вид обязательства'}</th>
						<th class="text-center p-w-xs">Долг (руб.)</th>
						<th class="text-center p-w-xs">На оплату (руб.)</th>
					</tr>
				</thead>
				<tbody class="text-center">
					{#if isPresent($form.payout_lines)}
						{#each $form.payout_lines as row}
							<tr>
								<td class="text-center">{formatDate(row.date_at)}</td>
								<td class="text-left">{row.expense_type_text}</td>
								<td class="text-right width-115px text-muted">{formatSum(Math.round((toFloat(row.unpaid_sum) - toFloat(row.sum)) * 100) / 100)}</td>
								<td class="text-right width-115px">
									<InputMoney
										value={row.sum}
										on:focus={() => (focusedByPayoutLine = true)}
										on:blur={() => (focusedByPayoutLine = false)}
										onchange={e => {
											if (focusedByPayoutLine) {
												manualControlByPayoutLines = true;
												focusedByPayoutLine = false;
											}
											row.sum = e.target.value;
											calcSums();
										}}
										klass={`text-right width-115px ${isPresent(row.sum) ? 'has-success' : ''}`}
									/>
								</td>
							</tr>
						{/each}
					{:else}
						<tr><td colspan=4 class="text-center text-muted">Нет данных</td></tr>
					{/if}
				</tbody>
				<tfoot>
					{#if isBlank($form.payout_lines)}
						<tr><td colspan="4" class="text-center text-muted">Нет данных</td></tr>
					{/if}
					<tr>
						<th class="text-right">ИТОГО:</th>
						<th></th>
						<th></th>
						<th class="text-right" class:text-green={isSumValid($form.sum, total_sum)} class:text-danger={!isSumValid($form.sum, total_sum)}>
							{formatSum(total_sum || 0)}
						</th>
					</tr>
					<tr>
						<th colspan="4">
							{#if !isSumValid($form.sum, total_sum)}
								<span class="form-text text-danger float-right">
									{manualControlByPayoutLines ? 'Отличается от суммы списания' : 'Сумма платежа выше необходимой'}
								</span>
							{/if}
						</th>
					</tr>
				</tfoot>
			</table>
		</div>
	{/if}
	<!-- Номер платёжки -->
	<Field name="num" label="Номер платежки" required={true}>
		<input {disabled} name="num" type="text" class="form-control" bind:value={$form.num} on:change={handleChange} placeholder="15" />
	</Field>
	<!-- Дата платёжки -->
	<Datepicker
		model='bank_account'
		attribute='num_at'
		label='Дата платежки'
		placeholder='дд.мм.гггг'
		maskOptions={{ mask: Date }}
		{disabled}
		bind:value={$form.num_at}
	/>
	<!-- Назначение платежа -->
	<Field name="note" label="Назначение платежа" required={true}>
		<textarea
			{disabled}
			name="note"
			rows="3"
			class="form-control"
			bind:value={$form.note}
			on:change={handleChange}
			placeholder={$form.kind == 'running_costs'
				? 'Текущий платеж в соответствии с п.2 ст.134 ФЗ “О несостоятельности (банкротстве)” 1-й очереди, расходы на публикацию управляющего должника за январь 0000 г. НДС не облагается'
				: 'Платеж в погашение 3-й очереди реестра требований кредиторов должника по договору займа от 00.00.0000 г. НДС не облагается'}
		/>
	</Field>
	<ScansSet
		label='Документы'
		scansSet={$form.scans_set}
		disabled={isBlank($form?.id) || !filledRequiredFields}
		on:changed={({detail: scansSet}) => $form.scans_set = scansSet}
	/>
	<ErrorMessage message={ $errorsBase } on:click={() => $errorsBase = null}/>
</Form>

<style lang="scss">
	.wrapper-info-icon {
		position: absolute;
		top: 0.5rem;
		right: -0.5rem;
		z-index: 10003;
	}
	.repayment_order__popover {
		position: absolute;
		z-index: 10010;
		background-color: #fff;
		border: solid 1px;
		border-radius: 0.3rem;
		box-shadow: 0 0 10px;
		display: block;
		padding: 0.7rem 1rem;
		top: 50px;
	}

	.loc-font {
		tr,
		th {
			font-size: 0.81rem;
		}
	}
</style>
