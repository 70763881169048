/** @format */

import { get, writable } from 'svelte/store';
import * as lib from 'svelte-forms-lib';
import { isPrimitive, isPresent, isBlank, isObject, isArray, replaceNulls } from 'utils/tools';

export const createForm = (config = {}) => {
	if (isBlank(config.validate)) config.validate = () => new Promise(resolve => resolve());

	const instance = lib.createForm(config);
	const errorsBase = writable({});
	const formFields = writable([]);

	const isFormCompleted = fields => {
		const f = get(instance.form);

		return fields.every(field => isPresent(f[field]));
	};

	const processErrors = error => {
		const errors = get(instance.errors);
		const visibleFields = get(formFields);

		if (error instanceof TypeError || error instanceof RangeError || error instanceof EvalError) {
			throw error;
		} else if (isObject(error)) {
			const other_errors = [];

			Object.keys(error).forEach(key => {
				if (key in errors && key in visibleFields) {
					instance.errors.update(h => {
						h[key] = isArray(error[key]) ? error[key].join(', ') : error[key];
						return h;
					});
				} else if (isArray(error[key]) && key in visibleFields) {
					error[key].forEach(mess => other_errors.push(key == 'base' ? mess : key + ': ' + mess));
				} else {
					other_errors.push(key == 'base' ? error[key] : key + ': ' + error[key]);
				}
			});

			errorsBase.set(isPresent(other_errors) ? other_errors : undefined);
		} else {
			errorsBase.set(error);
		}
	};

	return {
		...instance,
		updateInitialValues: values => instance.updateInitialValues(replaceNulls(values)),
		errorsBase,
		processErrors,
		formFields,
		isFormCompleted,
	};
};

let log_debug = false;

const log = (...args) => log_debug && console.log(...args);

export const addToFormData = (formData, name, value, debug = false, step = 1) => {
	log_debug = debug;

	log('name=' + name, 'step=' + step, value);

	if (isBlank(value)) {
		if (Array.isArray(value)) {
			log('Empty Array detected:', name, value);
			formData.append(name + '[]', []);
		} else {
			log('Empty detected:', name, value);
			formData.append(name, '');
		}
	} else if (isPrimitive(value)) {
		log('Primitive detected:', name, value);
		formData.append(name, value);
	} else if (value instanceof File) {
		log('File detected:', name, value);
		formData.append(name, value);
	} else if (value instanceof Date) {
		log('Date detected:', name);
		formData.append(name, value.toString());
	} else if (typeof value == 'object') {
		if (Array.isArray(value)) {
			if (value.length == 0) {
				log('Empty array detected:', name);
				formData.append(name + '[]', []);
			} else if (isPrimitive(value[0])) {
				log('Primitive array detected:', name);
				value.forEach(e => formData.append(name + '[]', e));
			} else {
				log('Array of objects detected:', name);
				value.forEach((val, idx) => {
					addToFormData(formData, name + '[' + idx + ']', val, debug, step + 1);
				});
			}
		} else {
			log('Object detected:', name);
			Object.keys(value).forEach(key => {
				addToFormData(formData, name + '[' + key + ']', value[key], debug, step + 1);
			});
		}
	} else {
		log('Unknown case detected:', name, value);
	}
};