<!-- @format -->
<script>
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { onMount } from 'svelte';
	import { showModal } from '../../components/modal.svelte';
	import { isArray } from 'utils/tools';
	import Spouse from 'models/Spouse';
	import SpouseModal from './_modal.svelte';
	import IconInfo from '~/svelte/_shared/icon-info.svelte';

	let spouses = [];
	let spouseId = null;
	let indexCollapse = false;
	let infoShow = false;

	const openModalForm = id => {
		spouseId = id;
		showModal('form-spouse-modal');
	};

	onMount(async () => {
		spouses = await Spouse.loadSpouses();
	});
</script>

<div class="form-group row" role="button" tabindex="0" on:click={$procedureAvailable && (() => (indexCollapse = !indexCollapse))} on:keypress|stopPropagation>
	<h4 class="spouses button_with_info_wrapper col-sm-12">
		<span class="hoverable">
			<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
			<span class="header m-l-xs">{`Супруги (${spouses.length})`}</span>
		</span>
		<div
			class="m-l-sm"
			role="button"
			tabindex="0"
			on:mouseover={$procedureAvailable && (() => (infoShow = true))}
			on:mouseout={() => (infoShow = false)}
			on:focus={$procedureAvailable && (() => (infoShow = true))}
			on:blur={() => (infoShow = false)}
		>
			<IconInfo />
		</div>
		{#if infoShow}
			<div class="order-of-repayment__popover show fade m-b-xs">
				Указываются текущий(ая) супруг(а) должника и предыдущие, с кем брак был расторгнут не позднее 3 лет с даты судебного акта о принятии заявления
			</div>
		{/if}
	</h4>
</div>
<div id="collapse-spouses" class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<button class="btn btn-xs btn-warning m-b-xs" disabled={!$procedureAvailable} on:click={$procedureAvailable && openModalForm(null)}> + Супруг(а) </button>
		<div class="p-1">
			{#if isArray(spouses)}
				<ol class="block-list">
					{#each spouses as spouse}
						<li>
							<div
								class="float-left"
								class:simulated-link={$procedureAvailable}
								class:simulated-link-disabled={!$procedureAvailable}
								role="button"
								tabindex="0"
								on:click={$procedureAvailable && openModalForm(spouse.id)}
								on:keypress|stopPropagation
							>
								{spouse.full_name || ''}
							</div>
						</li>
					{/each}
				</ol>
			{/if}
		</div>
	</div>
</div>
<SpouseModal {spouseId} on:submit={() => (spouses = Spouse.all())} on:delete={() => (spouses = Spouse.all())} />

<style lang="scss">
	.button_with_info_wrapper {
		position: relative;
		display: flex;
	}

	.order-of-repayment__popover {
		position: absolute;
		bottom: 1.2rem;
		left: 15px;
		z-index: 9999;
		width: 17vw;
		background-color: #fff;
		border: solid 1px;
		border-radius: 0.3rem;
		box-shadow: 0 0 10px;
		display: block;
		padding: 0.7rem 1rem;
		font-size: 0.81rem;
		color: #676a6c;
		font-weight: 400;
	}
</style>
