<!-- @format -->
<script>
	import { fetchGet } from 'utils/fetch';
	import Dialog from '~/svelte/_components/dialog.svelte';
	import TariffModal from './_modal.svelte';
	import { showModal } from '~/svelte/components/modal.svelte';

	const titles = [
		'Название',
		'Вид банкрота',
		'Фазы процедур',
		'Количество процедур',
		'Стоимость за единицу, руб.',
		'Способ оплаты',
		'Повторяющийся платёж',
		'Комбинируется с промокодом',
		'Активен',
	];

	const bankruptTypeOptions = [
		{ id: 'Person', text: 'Физическое лицо' },
		{ id: 'Organization', text: 'Юридическое лицо' },
	];

	const procedurePhasesOptions = {
		Person: [
			{ id: 1, text: 'Реструктуризация долгов' },
			{ id: 2, text: 'Реализация имущества' },
		],
		Organization: [
			{ id: 3, text: 'Наблюдение' },
			{ id: 4, text: 'Финансовое оздоровление' },
			{ id: 5, text: 'Внешнее управление' },
			{ id: 6, text: 'Конкурсное производство' },
		],
	};

	let selectedTariff = {};
	let deleteDialog;
	let tariff = null;

	const getTariffs = async () => await fetchGet('/api/admin/fee/tariffs');

	const selectTariff = tariff => (selectedTariff = tariff);

	const newTariff = () => {
		tariff = null;
		showModal('form-tariff-modal');
	};

	const editTariff = () => {
		tariff = selectedTariff;
		showModal('form-tariff-modal');
	};

	const confirmDeleteTariff = () => {
		deleteDialog.showModal();
	};

	const deleteTariff = () => {
		deleteDialog.close();
		alert('deleted!');
	};
</script>

<nav class="navbar navbar-fixed-top" style="z-index: 15">
	<div class="navbar-brand page_title">Тарифы</div>
	<div class="float-right">
		<button class="btn btn-xs btn-default ml-1" on:click={newTariff}><iconify-icon icon="fa:plus" /></button>
		<button class="btn btn-xs btn-default ml-1" disabled={!selectedTariff.id} on:click={editTariff}><iconify-icon icon="fa:pencil" /></button>
		<button class="btn btn-xs btn-default ml-1" disabled={!selectedTariff.id} on:click={confirmDeleteTariff}><iconify-icon icon="fa:trash-o" /></button>
	</div>
</nav>
<div style={'margin-top: 2.7rem;'}></div>
<div class="m-b-lg overlay p-0">
	<table class="table table-striped table-hover m-0">
		<thead class="thead-light">
			<tr>
				{#each titles as title}
					<th class="text-center p-w-xs">
						{title}
					</th>
				{/each}
			</tr>
		</thead>
		<tbody class="text-center">
			{#await getTariffs()}
				<tr class="no-data"><td class="text-secondary" colspan={titles.length}>Идёт загрузка...</td></tr>
			{:then tariffs}
				{#each tariffs as tariff (tariff.id)}
					<tr class="tariff" class:selected={selectedTariff.id === tariff.id} on:click={() => selectTariff(tariff)}>
						<td class="text-left">{tariff.name}</td>
						<td class="text-left">{tariff.bankrupt_type}</td>
						<td class="text-left">{tariff.procedure_phases}</td>
						<td class="text-left">{tariff.name}</td>
						<td class="text-left">{tariff.bankrupt_type}</td>
						<td class="text-left">{tariff.procedure_phases}</td>
						<td class="text-left">{tariff.name}</td>
						<td class="text-left">{tariff.bankrupt_type}</td>
						<td class="text-left">{tariff.procedure_phases}</td>
					</tr>
				{:else}
					<tr class="no-data"><td class="text-secondary" colspan={titles.length}>Нет данных</td></tr>
				{/each}
			{/await}
		</tbody>
	</table>
</div>

<!--Dialog title={`${selectedTariff.id ? 'Редактирование' : 'Создание'} тарифа`} bind:dialog={formDialog}>
  <Form slot='body' tariff={selectedTariff} {bankruptTypeOptions} {procedurePhasesOptions} on:cancel={() => formDialog.close()}/>
</Dialog-->

<TariffModal
	{tariff}
	{bankruptTypeOptions}
	{procedurePhasesOptions}
	on:submit={({ detail: tariff }) => {
		alert(JSON.stringify(tariff));
	}}
/>

<Dialog title="Удаление тарифа" bind:dialog={deleteDialog}>
	<div slot="body">
		Подтвердите удаление тарифа<br />
		«{selectedTariff.name}»
	</div>
	<div slot="footer">
		<button class="btn btn-sm btn-danger mr-1" on:click={deleteTariff}>Удалить</button>
		<button class="btn btn-sm btn-default mr-1" on:click={() => deleteDialog.close()}>Отменить</button>
	</div>
</Dialog>

<style lang="scss">
	.navbar-brand {
		padding: 0;
	}

	.overlay {
		padding: 0;
	}

	.selected {
		color: #495057;
		background-color: #e9ecef !important;
		border-color: #dee2e6;
	}

	iconify-icon {
		font-size: 1.4em;
	}
</style>
