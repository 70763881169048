<!-- @format -->
<script>
	import Modal from '~/svelte/components/modal.svelte';
	import RunningCostModal from '~/svelte/running_costs/_modal.svelte';
	import ReceivableForm from './_form.svelte';
	import { cardsTitle } from 'utils/formats';
	import { transmitter } from 'base_stores';

	export let index = 0;
	export let noCounterpartySelected;
	export let disabled = false;
	export let modalId = 'form-receivable-modal';

	let runningCostModal;

	let handleSubmit = () => {};
	let handleCancel = () => {};
	let handleDelete = () => {};

	const runningCostEdit = ({ detail: id }) => runningCostModal.editItem(id);
	const runningCostDeleted = ({ detail: id }) => ($transmitter.runningCosts = $transmitter.runningCosts.filter(rc => rc.id !== id));
	const runningCostUpdated = ({ detail: result }) =>
		($transmitter.runningCosts = $transmitter.runningCosts.map(rc =>
			rc.id === result.item.id ? { ...rc, sum: result.item.sum, date_at: result.item.date_at } : rc,
		));
</script>

<Modal
	{modalId}
	{index}
	deleteIconDisabled={!$transmitter.receivableId || disabled}
	submitButtonAction={handleSubmit}
	cancelButtonAction={handleCancel}
	deleteButtonAction={handleDelete}
	submitButtonDisabled={noCounterpartySelected || disabled}
	form={true}
	size="large"
>
	<h2 slot="header" class="creditors_requirement-modal__header">{cardsTitle('дебиторки', $transmitter.receivableId)}</h2>
	<div slot="body">
		<ReceivableForm
			{disabled}
			bind:handleSubmit
			bind:handleCancel
			bind:handleDelete
			bind:noCounterpartySelected
			on:editRunningCost={runningCostEdit}
			on:create
			on:update
			on:delete
		/>
	</div>
</Modal>

<RunningCostModal {disabled} bind:this={runningCostModal} index={index + 1} on:update={runningCostUpdated} on:delete={runningCostDeleted} />
