<!-- @format -->
<script context="module">
	let modalWindows = {};

	export function showModal(modalId) {
		if (modalWindows[modalId]) {
			modalWindows[modalId].show();
		}
	}
	export function hideModal(modalId) {
		if (modalWindows[modalId]) {
			modalWindows[modalId].hide();
		}
	}
</script>

<script>
	import { createEventDispatcher } from 'svelte';
	import { onMount, onDestroy } from 'svelte';
	import SvelteTooltip from 'svelte-tooltip';
	import { isArray, isObject, debounce, isBlank, isPresent } from 'utils/tools';

	export let modalId;
	export let size;
	export let index = 0;
	export let form = false;
	export let withClose = true;
	export let deleteConfirmMessage = 'Вы действительно хотите удалить запись?';
	export let cancelButtonText = 'Отменить';
	export let cancelButtonCss = 'btn-white';
	export let cancelButtonDisabled = false;
	export let cancelButtonAction = () => {};
	export let submitButtonText = 'Сохранить';
	export let submitButtonCss = 'btn-primary';
	export let submitButtonDisabled = false;
	export let submitButtonAction = () => {};
	export let hideAfterSubmit = true;
	export let additionalActionButtonShow = false;
	export let additionalActionButtonText = '';
	export let additionalActionButtonCss = 'btn-warning';
	export let additionalActionButtonDisabled = false;
	export let additionalActionButtonAction = () => {};
	export let deleteButtonText = 'Удалить';
	export let deleteButtonCss = 'btn-danger';
	export let deleteButtonDisabled = false;
	export let deleteButtonAction = () => {};
	export let deleteButton = false;
	export let deleteIcon = !deleteButton;
	export let deleteIconDisabled = false;
	export let deleteTipDisabled = 'Нельзя удалить';
	export let showCopyAndSaveButton = false;
	export let copyAndSaveButtonDisabled = false;
	export let copyAndSaveButtonAction = () => {};
	export let rightButtonCss = 'btn-white';
	export let rightButtonText = '';
	export let rightButtonAction = () => {};
	export let hint = '';
	export let showError = true;

	// жуткий костыль, но иначе требуется много времени для переделки
	export let withFooter = true;

	let visible = false;
	let loading = false;
	let additionalActionLoading = false;
	let error;

	const dispatch = createEventDispatcher();

	const hide = () => {
		visible = false
		loading = false
		additionalActionLoading = false
		updateBodyClass()
		dispatch('hide')
	}

	const show = () => {
		visible = true
		updateBodyClass()
		dispatch('show')
	}

	const updateBodyClass = () => {
		if (parseInt(index) == 0) {
			if (visible) {
				document.body.classList.add('raf-modal-show');
			} else {
				document.body.classList.remove('raf-modal-show');
			}
		}
	};

	const handleSubmit = () =>
		debounce(() => {
			if (submitButtonDisabled) {
				return;
			}

			loading = true;
			error = null;
			const result = submitButtonAction();

			if (result instanceof Promise) {
				result
					.then(() => {
						if (hideAfterSubmit) {
							hide();
						}
					})
					.catch(e => {
						error = e;
						loading = false;
						throw e;
					});
			} else {
				loading = false;

				if (result === false) {
					// do nothing, form has errors
				} else if (hideAfterSubmit) {
					hide();
				}
			}
		}, 200);

	const handleAdditionalAction = () =>
		debounce(() => {
			additionalActionLoading = true;
			error = null;
			const result = additionalActionButtonAction();

			if (result instanceof Promise) {
				result
					.then(() => hide())
					.catch(e => {
						error = e;
						additionalActionLoading = false;
						throw e;
					});
			} else {
				hide();
			}
		}, 200);

	const handleCancel = () => {
		error = null;
		dispatch('cancel');
		cancelButtonAction();
		hide();
	};

	const handleDelete = () =>
		debounce(() => {
			loading = true;

			if (confirm(deleteConfirmMessage)) {
				error = null;
				const result = deleteButtonAction();

				if (result instanceof Promise) {
					result
						.then(() => hide())
						.catch(e => {
							error = e;
							loading = false;
							throw e;
						});
				} else {
					hide();
				}
			} else {
				loading = false;
			}
		}, 200);

	const handleRightButton = () => {
		rightButtonAction();
	};

	const handleCopyAndSave = () => debounce(() => copyAndSaveButtonAction(), 300);

	onDestroy(() => hide());

	onMount(() => (modalWindows[modalId] = { show, hide }));
</script>

{#if visible}
	<div class="raf-modal-background" style="z-index: {3000 + index * 50}"></div>
	<div class="raf-modal" id={modalId} role="dialog" style="z-index: {3001 + index * 50}">
		<div
			class="raf-modal-dialog"
			class:modal-sm={size === 'small'}
			class:modal-md={size === 'medium'}
			class:modal-md-lg={size === 'medium-large'}
			class:modal-lg={size === 'large'}
			class:modal-xl={size === 'extra-large'}
		>
			<div class="raf-modal-content animated fadeIn">
				{#if withClose}
					<button class:d-none={loading} type="button" class="close" on:click={handleCancel}>
						<span>×</span>
					</button>
				{/if}
				{#if $$slots.header}
					<div class="raf-modal-header">
						<slot name="header"></slot>
					</div>
				{/if}
				{#if $$slots.body}
					<div class="raf-modal-body">
						<slot name="body"></slot>
						{#if showError && isPresent(error)}
							<div class="mt-2">
								<div class="alert alert-danger mb-0">
									{#if isArray(error)}
										<ul>
											{#each error as mess}<li>{mess}</li>{/each}
										</ul>
									{:else if isObject(error)}
										<ul>
											{#each Object.keys(error) as key}<li>{error[key]}</li>{/each}
										</ul>
									{:else}
										<span>{error}</span>
									{/if}
									<button type="button" class="close" data-dismiss="alert" aria-label="Close" on:click={() => (error = null)}>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
							</div>
						{/if}
					</div>
				{/if}
				{#if withFooter}
					<div class="raf-modal-footer" class:only-footer={!$$slots.header && !$$slots.body}>
						<slot name="footer">
							<slot name="submit">
								<button
									type="button"
									class={`btn btn-sm float-left m-r-xs ${submitButtonCss}`}
									disabled={submitButtonDisabled || loading}
									on:click={handleSubmit}
								>
									{submitButtonText}
									{#if loading}
										<i class="fa fa-spinner fa-pulse fa-lg m-l-xs" />
									{/if}
								</button>
							</slot>
							<slot name="additionalAction">
								{#if additionalActionButtonShow}
									<div class="float-left raf__with-tooltip" class:m-r-xs={form && isBlank(hint)}>
										<button
											type="button"
											class={`btn btn-sm ${additionalActionButtonCss}`}
											disabled={additionalActionButtonDisabled || additionalActionLoading}
											on:click={handleAdditionalAction}
										>
											{additionalActionButtonText}
											{#if additionalActionLoading}
												<i class="fa fa-spinner fa-pulse fa-lg m-l-xs" />
											{/if}
										</button>
										{#if isPresent(hint)}<div class="raf__tooltip">{hint}</div>{/if}
									</div>
								{/if}
							</slot>
							<button
								type="button"
								class={`btn btn-sm float-left ${cancelButtonCss}`}
								class:m-l-xs={form && isPresent(hint)}
								disabled={cancelButtonDisabled || loading}
								on:click={handleCancel}
							>
								{cancelButtonText}
							</button>
							{#if form}
								{#if deleteIcon}
									{#if deleteIconDisabled || loading}
										<div class="float-right action-btn">
											<SvelteTooltip tip={deleteTipDisabled} left>
												<iconify-icon icon="bx-bxs-trash" height="24" class="cursor_pointer text-disabled" />
											</SvelteTooltip>
										</div>
									{:else}
										<div class="float-right action-btn" role="button" tabindex="0" on:click={handleDelete} on:keypress|stopPropagation>
											<SvelteTooltip tip="Удалить" left>
												<iconify-icon icon="bx-bxs-trash" height="24" class="cursor_pointer" />
											</SvelteTooltip>
										</div>
									{/if}
								{/if}
								{#if deleteButton}
									{#if deleteButtonDisabled}
										<div class="float-right">
											<SvelteTooltip tip={deleteTipDisabled} left>
												<button
													type="button"
													class={`btn btn-sm ${deleteButtonCss}`}
													disabled={deleteButtonDisabled || loading}
													data-confirm={deleteConfirmMessage}
													on:click={handleDelete}
												>
													{deleteButtonText}
												</button>
											</SvelteTooltip>
										</div>
									{:else}
										<button type="button" class={`btn btn-sm float-right ${deleteButtonCss}`} disabled={deleteButtonDisabled} on:click={handleDelete}>
											{deleteButtonText}
										</button>
									{/if}
								{/if}
							{/if}
							{#if showCopyAndSaveButton}
								{#if copyAndSaveButtonDisabled || loading}
									<div class="float-right action-btn">
										<SvelteTooltip tip="Нельзя дублировать" left>
											<iconify-icon icon="bx-bxs-copy" height="24" class="cursor_pointer text-disabled" />
										</SvelteTooltip>
									</div>
								{:else}
									<div class="float-right action-btn" role="button" tabindex="0" on:click={handleCopyAndSave} on:keypress|stopPropagation>
										<SvelteTooltip tip="Дублировать" left>
											<iconify-icon icon="bx-bxs-copy" height="24" class="cursor_pointer" />
										</SvelteTooltip>
									</div>
								{/if}
							{/if}
							{#if isPresent(rightButtonText)}
								<div class="float-right raf__with-tooltip" class:m-r-xs={form && isBlank(hint)}>
									<button type="button" class={`btn ${rightButtonCss}`} on:click={handleRightButton}>
										{rightButtonText}
									</button>
									{#if isPresent(hint)}<div class="raf__tooltip">{hint}</div>{/if}
								</div>
							{/if}
							{#if $$slots.footerRight}
								<slot name="footerRight"></slot>
							{/if}
						</slot>
					</div>
				{/if}
			</div>
		</div>
	</div>
{/if}

<style>
	:global(.raf-modal-show .raf-modal) {
		overflow-y: scroll;
	}

	:global(.raf-modal-show) {
		overflow: hidden;
	}

	:global(.raf-modal-header > h1, .raf-modal-header > h2, .raf-modal-header > h3) {
		margin-top: 0;
		margin-bottom: 0;
	}

	@media (min-width: 576px) {
		.raf-modal-dialog {
			max-width: 500px;
			margin: 1.75rem auto;
		}

		.modal-sm {
			max-width: 300px;
		}

		.modal-md {
			max-width: 560px;
		}

		.modal-md-lg,
		.modal-lg,
		.modal-xl {
			max-width: 576px;
		}
	}

	@media (min-width: 992px) {
		.modal-md {
			max-width: 560px;
		}

		.modal-md-lg {
			max-width: 680px;
		}

		.modal-lg {
			max-width: 800px;
		}

		.modal-xl {
			max-width: 992px;
		}
	}

	.raf-modal {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		outline: 0;
		overflow-y: scroll;
	}

	.close {
		position: absolute;
		top: 0.7rem;
		right: 0.8rem;
	}

	.raf-modal-dialog {
		position: relative;
		width: auto;
		pointer-events: none;
		margin: 1.75rem auto;
	}

	.raf-modal-content {
		position: relative;
		background-clip: padding-box;
		background-color: #fff;
		border: 1px solid#000;
		border-radius: 4px;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
		outline: 0 none;
		display: flex;
		flex-direction: column;
		width: 100%;
		pointer-events: auto;
	}

	.raf-modal-header {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 1rem;
		border-bottom: 1px solid #dee2e6;
		border-top-left-radius: 0.3rem;
		border-top-right-radius: 0.3rem;
		text-align: center;
		display: block;
	}

	.raf-modal-body {
		max-height: calc(100vh - 190px);
		padding: 1.2rem 2rem;
		overflow-y: auto;
	}

	.raf-modal-footer {
		padding: 1rem;
		border-top: 1px solid #dee2e6;
		border-bottom-right-radius: 0.3rem;
		border-bottom-left-radius: 0.3rem;
		font-size: 0.8125rem;
	}

	.only-footer {
		border-top-right-radius: 0.3rem;
		border-top-left-radius: 0.3rem;
		padding: 1rem 1.7rem 1rem 0.5rem;
	}

	.raf-modal-background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: #000;
		opacity: 50%;
	}

	.action-btn {
		margin-top: 10px;
		margin-left: 10px;
	}
</style>
